import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";

const CategoriesAndProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const [activeSubcategory, setActiveSubcategory] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  const navigate = useNavigate();

  // Handle screen resize
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch products from Firestore
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const productList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProducts(productList);

        // Extract unique categories
        const uniqueCategories = [
          ...new Set(productList.map((p) => p.category)),
        ].map((category) => ({
          name: category,
          image:
            productList.find((p) => p.category === category)?.mainImageUrl ||
            "https://via.placeholder.com/300",
        }));

        setCategories(uniqueCategories);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // Update subcategories automatically when a category is selected
  useEffect(() => {
    if (!activeCategory) {
      setSubcategories([]);
      setActiveSubcategory("");
    } else {
      const relatedProducts = products.filter(
        (p) => p.category === activeCategory
      );
      const uniqueSubcategories = [
        ...new Set(relatedProducts.map((p) => p.subCategory || "Other")),
      ];

      setSubcategories(uniqueSubcategories);
      setActiveSubcategory(
        uniqueSubcategories.length > 0 ? uniqueSubcategories[0] : ""
      );
    }
  }, [activeCategory, products]);

  // Filter products based on active category and subcategory
  const filteredProducts = products.filter(
    (p) =>
      p.category === activeCategory &&
      (!activeSubcategory || p.subCategory === activeSubcategory)
  );

  return (
    <div>
      {/* Categories Section */}
      <div className='my-5'>
        <h1 className='text-center mb-4' style={{ color: "#2c6449" }}>
          Categories
        </h1>
        <div className='row'>
          {categories.map((category, index) => (
            <div className='col-md-3 col-sm-6 mb-4' key={index}>
              <div
                className={`card position-relative ${
                  activeCategory === category.name
                    ? "border border-success"
                    : ""
                }`}
                onClick={() => setActiveCategory(category.name)}
                style={{
                  cursor: "pointer",
                  borderRadius: "10px",
                  overflow: "hidden",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  height: "200px",
                }}
              >
                <img
                  src={category.image}
                  alt={category.name}
                  className='card-img-top'
                  style={{ objectFit: "cover", height: "100%", width: "100%" }}
                />
                <div
                  className='position-absolute w-100 text-center py-2'
                  style={{ background: "rgba(0, 0, 0, 0.6)", bottom: 0 }}
                >
                  <h5 className='text-white m-0'>{category.name}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Subcategory Section */}
      {activeCategory && subcategories.length > 0 && (
        <div className='container my-3'>
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className='text-muted m-0'>
              {filteredProducts.length} Products
            </h5>

            {isSmallScreen ? (
              <select
                value={activeSubcategory}
                onChange={(e) => setActiveSubcategory(e.target.value)}
                className='form-control w-auto'
              >
                {subcategories.map((sub) => (
                  <option key={sub} value={sub}>
                    {sub}
                  </option>
                ))}
              </select>
            ) : (
              <div className='d-flex gap-2 flex-wrap'>
                {subcategories.map((sub) => (
                  <button
                    key={sub}
                    onClick={() => setActiveSubcategory(sub)}
                    className={`btn ${
                      activeSubcategory === sub
                        ? "btn-success text-white"
                        : "btn-outline-success"
                    }`}
                  >
                    {sub}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Products List */}
      <div className='container my-5'>
        <div className='row'>
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => {
              const mainImage =
                product.mainImageUrl || "https://via.placeholder.com/300";
              const category = product.category || "Unknown Category";
              const subCategory = product.subCategory || "Unknown Sub Category";
              const minOrder = product.priceRanges?.[0]?.minQty || "N/A";
              const origin = product.mainLocation || "N/A";

              // ✅ Extract Prices from priceRanges
              const priceRanges = product.priceRanges || [];
              const prices = priceRanges
                .map((range) => range.price)
                .filter(Boolean);
              const lowestPrice = prices.length ? Math.min(...prices) : "N/A";
              const highestPrice = prices.length ? Math.max(...prices) : "N/A";

              // ✅ Format the Price Range Display

              return (
                <div className='col-md-3 col-sm-6 mb-4' key={product.id}>
                  <div className='card h-100 shadow-sm'>
                    <img
                      src={mainImage}
                      className='card-img-top'
                      alt={product.productName || "Unnamed Product"}
                      style={{ objectFit: "cover", height: "200px" }}
                    />
                    <div className='card-body'>
                      <p className='text-muted mb-1'>{category}</p>
                      <p className='text-primary mb-1'>{subCategory}</p>
                      <h5 className='card-title'>{product.productName}</h5>

                      {/* Price Range */}
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          margin: "3px 0",
                          lineHeight: "1.2",
                        }}
                      >
                        {/^[0-9]+(\.[0-9]+)?$/.test(lowestPrice) &&
                        /^[0-9]+(\.[0-9]+)?$/.test(highestPrice)
                          ? `SR ${lowestPrice} - SR ${highestPrice}`
                          : "Pricing - Refer to Supplier"}
                      </p>
                      <p className='text-muted mb-1'>
                        Min. order: {minOrder} piece(s)
                      </p>
                      <p className='text-muted'>Origin: {origin}</p>
                      <button
                        onClick={() => navigate(`/product/${product.id}`)}
                        className='btn btn-success w-100'
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p className='text-center'>
              No products found in this subcategory.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoriesAndProductsPage;
