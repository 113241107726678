// your imports stay the same
import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import {
  MessageCircle,
  User,
  ShoppingCart,
  Search,
  Menu,
  Bell,
} from "react-feather";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/logo.svg";
import TopBar from "./TopBar";
import SecondaryNavbar from "./SecondaryNavbar";
import { useTranslation } from "react-i18next";
import { useCart } from "../../context/CartContext";
import RfqModal from "../../pages/RfqPage";
import { useNotification } from "../../context/NotificationContext";

// inside Navbar component...
const Navbar = () => {
  const { currentUser, userData, loading, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const iconColor = "#2c6449";
  const [showSecondaryNavbar, setShowSecondaryNavbar] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showRFQModal, setShowRFQModal] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);

  const searchRef = useRef(null);

  const { cartItemCount } = useCart();
  const { notifications, removeNotification, seenNotifications } =
    useNotification();

  const activeNotifications = notifications.filter(
    (notif) => notif.paymentLink && !seenNotifications.has(notif.id)
  );

  const { i18n, t } = useTranslation();

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    document.documentElement.dir = language === "ar" ? "rtl" : "ltr";
  };

  useEffect(() => {
    document.documentElement.setAttribute(
      "dir",
      i18n.language === "ar" ? "rtl" : "ltr"
    );
  }, [i18n.language]);

  useEffect(() => {
    const handleScroll = () => {
      setShowSecondaryNavbar(window.scrollY > 100);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowMobileSearch(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  if (loading) {
    return (
      <div className='text-center'>
        <div className='spinner-border text-primary' role='status'></div>
      </div>
    );
  }

  const handleUserIconClick = () => {
    if (userData) {
      const { role } = userData;
      const currentPath = location.pathname;

      if (role === "buyer" && currentPath !== "/buyer-dashboard") {
        navigate("/buyer-dashboard");
      } else if (role === "supplier" && currentPath !== "/supplier-dashboard") {
        navigate("/supplier-dashboard");
      } else if (role === "admin" && currentPath !== "/admin-dashboard") {
        navigate("/admin-dashboard");
      }
    } else {
      if (location.pathname !== "/user-login") {
        navigate("/user-login");
      }
    }
  };

  const handleSearch = () => {
    if (searchQuery.trim()) {
      navigate(`/search?query=${searchQuery}`);
    }
  };

  return (
    <div>
      <div className='d-none d-md-block'>
        <TopBar />
      </div>

      <nav className='navbar navbar-expand-lg bg-body-tertiary'>
        <div className='container'>
          {/* Logo */}
          <a className='navbar-brand' href='/'>
            <img src={logo} alt='Logo' style={{ height: "100px" }} />
          </a>

          {/* Mobile Icons */}
          <div className='d-flex align-items-center d-lg-none position-relative'>
            {/* Search Toggle */}
            <div className='me-2 position-relative' ref={searchRef}>
              <button
                className='btn btn-link'
                onClick={() => setShowMobileSearch((prev) => !prev)}
                style={{ color: iconColor }}
              >
                <Search size={20} />
              </button>
              {showMobileSearch && (
                <div
                  className='position-absolute bg-white p-2 rounded shadow'
                  style={{ top: "40px", right: 0, width: "200px", zIndex: 999 }}
                >
                  <div className='input-group'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Search...'
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button
                      className='btn btn-outline-secondary'
                      onClick={() => {
                        handleSearch();
                        setShowMobileSearch(false);
                      }}
                    >
                      <Search size={16} />
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Cart */}
            <button
              className='btn btn-link me-2 position-relative'
              style={{ color: iconColor }}
              onClick={() => navigate("/cart")}
            >
              <ShoppingCart size={20} />
              {cartItemCount > 0 && (
                <span
                  className='badge bg-danger rounded-circle position-absolute'
                  style={{
                    top: "-5px",
                    right: "-8px",
                    fontSize: "0.7rem",
                    padding: "4px 6px",
                    minWidth: "16px",
                    textAlign: "center",
                  }}
                >
                  {cartItemCount}
                </span>
              )}
            </button>

            {/* User Icon */}
            <div className='dropdown'>
              <button
                className='btn btn-link dropdown-toggle'
                id='userDropdownMobile'
                data-bs-toggle='dropdown'
                style={{ color: iconColor }}
              >
                <User size={20} />
              </button>
              <ul className='dropdown-menu dropdown-menu-end'>
                {currentUser && userData ? (
                  <>
                    <li>
                      <span
                        className='dropdown-item-text fw-bold'
                        style={{ color: iconColor }}
                      >
                        Hi, {userData.name || "User"}!
                      </span>
                    </li>
                    <li>
                      <hr className='dropdown-divider' />
                    </li>
                    <li>
                      <button
                        className='dropdown-item'
                        onClick={handleUserIconClick}
                      >
                        My Dashboard
                      </button>
                    </li>
                    <li>
                      <button
                        className='dropdown-item'
                        onClick={() => navigate("/orders")}
                      >
                        Order History
                      </button>
                    </li>
                    <li>
                      <hr className='dropdown-divider' />
                    </li>
                    <li>
                      <button className='dropdown-item' onClick={logout}>
                        {t("navbar.logout")}
                      </button>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <button
                        className='dropdown-item'
                        onClick={() => navigate("/user-login")}
                      >
                        Login
                      </button>
                    </li>
                    <li>
                      <button
                        className='dropdown-item'
                        onClick={() => navigate("/user-login")}
                      >
                        Register
                      </button>
                    </li>
                  </>
                )}
              </ul>
            </div>

            {/* Hamburger */}
            <button
              className='btn btn-link ms-2'
              data-bs-toggle='collapse'
              data-bs-target='#navbarNavDropdown'
              style={{ color: iconColor }}
            >
              <Menu size={24} />
            </button>
          </div>

          {/* Main Menu */}
          <div
            className='collapse navbar-collapse justify-content-between'
            id='navbarNavDropdown'
          >
            {/* Left Nav */}
            <ul
              className='navbar-nav me-auto justify-content-center flex-grow-1'
              style={{ color: iconColor }}
            >
              <li className='nav-item'>
                <a
                  className='nav-link active'
                  href='/'
                  style={{ color: iconColor }}
                >
                  {t("navbar.Home")}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  href='/features'
                  style={{ color: iconColor }}
                >
                  {t("navbar.Featured Products")}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  href='/categories'
                  style={{ color: iconColor }}
                >
                  {t("navbar.Categories")}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  href='/contact'
                  style={{ color: iconColor }}
                >
                  {t("navbar.Contact")}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  href='/'
                  onClick={(e) => {
                    e.preventDefault();
                    setShowRFQModal(true);
                  }}
                  style={{ color: iconColor }}
                >
                  {t("navbar.rfqs")}
                </a>
              </li>
            </ul>

            <RfqModal
              show={showRFQModal}
              onClose={() => setShowRFQModal(false)}
            />

            {/* Right Side (Desktop Only) */}
            <div className='d-none d-lg-flex align-items-center'>
              {/* Search */}
              <div className='input-group me-3'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Search...'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    maxWidth: "300px",
                    border: "1px solid #2c6449",
                    color: "#2c6449",
                  }}
                />
                <button
                  className='btn btn-outline-secondary'
                  onClick={handleSearch}
                >
                  <Search size={16} />
                </button>
              </div>

              {/* Notification Bell */}
              <div className='dropdown position-relative me-3'>
                <button
                  className='btn btn-link position-relative'
                  id='notificationDropdown'
                  data-bs-toggle='dropdown'
                  style={{ color: iconColor }}
                >
                  <Bell size={22} />
                  {activeNotifications.length > 0 && (
                    <span
                      className='badge bg-danger rounded-circle position-absolute'
                      style={{
                        top: "-5px",
                        right: "-8px",
                        fontSize: "0.7rem",
                        padding: "4px 6px",
                        minWidth: "16px",
                        textAlign: "center",
                      }}
                    >
                      {activeNotifications.length}
                    </span>
                  )}
                </button>
                <ul className='dropdown-menu dropdown-menu-end'>
                  {activeNotifications.map((notif) => (
                    <li key={notif.id}>
                      <a
                        href={notif.paymentLink}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='dropdown-item'
                        onClick={() => removeNotification(notif.id)}
                      >
                        {notif.message}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Cart */}
              <button
                className='btn btn-link me-2 position-relative'
                onClick={() => navigate("/cart")}
                style={{ color: iconColor }}
              >
                <ShoppingCart size={20} />
                {cartItemCount > 0 && (
                  <span
                    className='badge bg-danger rounded-circle position-absolute'
                    style={{
                      top: "-5px",
                      right: "-8px",
                      fontSize: "0.7rem",
                      padding: "4px 6px",
                      minWidth: "16px",
                    }}
                  >
                    {cartItemCount}
                  </span>
                )}
              </button>

              {/* Messages */}
              <button
                className='btn btn-link me-2'
                style={{ color: iconColor }}
              >
                <MessageCircle size={20} />
              </button>

              {/* User */}
              <div className='dropdown'>
                <button
                  className='btn btn-link dropdown-toggle'
                  id='userDropdown'
                  data-bs-toggle='dropdown'
                  style={{ color: iconColor }}
                >
                  <User size={20} />
                </button>
                <ul className='dropdown-menu dropdown-menu-end'>
                  {currentUser && userData ? (
                    <>
                      <li>
                        <span
                          className='dropdown-item-text fw-bold'
                          style={{ color: iconColor }}
                        >
                          Hi, {userData.name || "User"}!
                        </span>
                      </li>
                      <li>
                        <hr className='dropdown-divider' />
                      </li>
                      <li>
                        <button
                          className='dropdown-item'
                          onClick={handleUserIconClick}
                        >
                          My Dashboard
                        </button>
                      </li>
                      <li>
                        <button
                          className='dropdown-item'
                          onClick={() => navigate("/orders")}
                        >
                          Order History
                        </button>
                      </li>
                      <li>
                        <hr className='dropdown-divider' />
                      </li>
                      <li>
                        <button className='dropdown-item' onClick={logout}>
                          {t("logout")}
                        </button>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <button
                          className='dropdown-item'
                          onClick={() => navigate("/user-login")}
                        >
                          Login
                        </button>
                      </li>
                      <li>
                        <button
                          className='dropdown-item'
                          onClick={() => navigate("/user-login")}
                        >
                          Register
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </div>

              {/* Language Selector */}
              <select
                onChange={(e) => handleLanguageChange(e.target.value)}
                defaultValue={i18n.language}
                style={{
                  backgroundColor: "transparent",
                  color: iconColor,
                  border: "none",
                  outline: "none",
                  fontSize: "16px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
              >
                <option value='en'>English</option>
                <option value='ar'>العربية</option>
              </select>
            </div>
          </div>
        </div>
      </nav>

      {/* Secondary Navbar */}
      <div className='d-none d-md-block'>
        {showSecondaryNavbar && <SecondaryNavbar />}
      </div>
    </div>
  );
};

export default Navbar;
