import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useAuth } from "../../context/AuthContext";
import Icon from "feather-icons-react";
import Products from "../admin/AdminProducts";
import Suppliers from "../admin/AdminSuppliersAdd";
import Settings from "../admin/AdminSettings";
import BuyerList from "../admin/BuyerList";
import { useTranslation } from "react-i18next";

const AdminDashboard = () => {
  const auth = getAuth();
  const { userData } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedPage, setSelectedPage] = useState(
    () => localStorage.getItem("selectedPage") || "dashboard"
  );

  useEffect(() => {
    if (!auth.currentUser || userData?.role !== "admin") {
      navigate("/admin-login");
    }
  }, [auth.currentUser, userData, navigate]);

  useEffect(() => {
    localStorage.setItem("selectedPage", selectedPage);
  }, [selectedPage]);

  const handleTabClick = (page) => {
    setSelectedPage(page);
  };

  const renderContent = () => {
    switch (selectedPage) {
      case "dashboard":
        return (
          <h4 className='text-success fw-bold'>
            {t("admin_dashboard.dashboard_overview")}
          </h4>
        );
      case "products":
        return <Products />;
      case "settings":
        return <Settings />;
      case "suppliers":
        return <Suppliers />;
      case "buyers":
        return <BuyerList />;
      case "admins":
        return <h4 className='text-success fw-bold'>Admins Management</h4>;
      case "product-library":
        return <h4 className='text-success fw-bold'>Product Library</h4>;
      case "orders":
        return <h4 className='text-success fw-bold'>Manage Orders</h4>;
      case "abandoned-carts":
        return <h4 className='text-danger fw-bold'>Abandoned Carts</h4>;
      case "analytics-overview":
        return <h4 className='text-primary fw-bold'>Analytics Overview</h4>;
      case "live-analytics":
        return <h4 className='text-info fw-bold'>Live Analytics</h4>;
      case "analytics-reports":
        return <h4 className='text-warning fw-bold'>Analytics Reports</h4>;
      case "seo-enhancements":
        return <h4 className='text-success fw-bold'>SEO Enhancements</h4>;
      case "promo-code":
        return <h4 className='text-info fw-bold'>Promo Code Management</h4>;
      case "coupon-code":
        return <h4 className='text-warning fw-bold'>Coupon Code Management</h4>;
      default:
        return <h4 className='text-danger fw-bold'>Page Not Found</h4>;
    }
  };

  return (
    <div className='container mt-3'>
      {/* 🔹 Hamburger Menu for Small & Medium Screens */}
      <button
        className='btn btn-primary d-lg-none mb-3'
        type='button'
        data-bs-toggle='collapse'
        data-bs-target='#mobileMenu'
      >
        ☰ Menu
      </button>

      {/* 🔹 Mobile Menu (Collapsible) */}
      <div className='collapse d-lg-none' id='mobileMenu'>
        <ul className='list-group'>
          <li
            className='list-group-item'
            onClick={() => handleTabClick("dashboard")}
          >
            <Icon icon='home' /> {t("admin_dashboard.home")}
          </li>

          {/* 🔹 User Management */}
          <li
            className='list-group-item'
            data-bs-toggle='collapse'
            data-bs-target='#userMenu'
          >
            <Icon icon='users' /> {t("admin_dashboard.user_management")} ▼
          </li>
          <ul className='collapse list-group' id='userMenu'>
            <li
              className='list-group-item'
              onClick={() => handleTabClick("suppliers")}
            >
              🏭 {t("admin_dashboard.suppliers")}
            </li>
            <li
              className='list-group-item'
              onClick={() => handleTabClick("buyers")}
            >
              👥 {t("admin_dashboard.buyers")}
            </li>
            <li
              className='list-group-item'
              onClick={() => handleTabClick("admins")}
            >
              🔑 {t("admin_dashboard.admins")}
            </li>
          </ul>

          {/* 🔹 Product Management */}
          <li
            className='list-group-item'
            data-bs-toggle='collapse'
            data-bs-target='#productMenu'
          >
            <Icon icon='box' /> {t("admin_dashboard.product_management")} ▼
          </li>
          <ul className='collapse list-group' id='productMenu'>
            <li
              className='list-group-item'
              onClick={() => handleTabClick("products")}
            >
              📦 {t("admin_dashboard.products")}
            </li>
            <li
              className='list-group-item'
              onClick={() => handleTabClick("product-library")}
            >
              📚 {t("admin_dashboard.product_library")}
            </li>
          </ul>

          {/* 🔹 Order Management */}
          <li
            className='list-group-item'
            data-bs-toggle='collapse'
            data-bs-target='#orderMenu'
          >
            <Icon icon='shopping-bag' /> {t("admin_dashboard.order_management")}{" "}
            ▼
          </li>
          <ul className='collapse list-group' id='orderMenu'>
            <li
              className='list-group-item'
              onClick={() => handleTabClick("orders")}
            >
              📦 {t("admin_dashboard.orders")}
            </li>
            <li
              className='list-group-item'
              onClick={() => handleTabClick("abandoned-carts")}
            >
              🛑 {t("admin_dashboard.abandoned_carts")}
            </li>
          </ul>

          {/* 🔹 Analytics */}
          <li
            className='list-group-item'
            data-bs-toggle='collapse'
            data-bs-target='#analyticsMenu'
          >
            <Icon icon='bar-chart-2' /> {t("admin_dashboard.analytics")} ▼
          </li>
          <ul className='collapse list-group' id='analyticsMenu'>
            <li
              className='list-group-item'
              onClick={() => handleTabClick("analytics-overview")}
            >
              📊 {t("admin_dashboard.overview")}
            </li>
            <li
              className='list-group-item'
              onClick={() => handleTabClick("live-analytics")}
            >
              📡 {t("admin_dashboard.live_analytics")}
            </li>
            <li
              className='list-group-item'
              onClick={() => handleTabClick("analytics-reports")}
            >
              📑 {t("admin_dashboard.reports")}
            </li>
          </ul>

          {/* 🔹 Marketing */}
          <li
            className='list-group-item'
            data-bs-toggle='collapse'
            data-bs-target='#marketingMenu'
          >
            <Icon icon='search' /> {t("admin_dashboard.marketing")} ▼
          </li>
          <ul className='collapse list-group' id='marketingMenu'>
            <li
              className='list-group-item'
              onClick={() => handleTabClick("seo-enhancements")}
            >
              🌍 {t("admin_dashboard.seo_enhancements")}
            </li>
            <li
              className='list-group-item'
              onClick={() => handleTabClick("promo-code")}
            >
              🎟 {t("admin_dashboard.promo_code")}
            </li>
            <li
              className='list-group-item'
              onClick={() => handleTabClick("coupon-code")}
            >
              💰 {t("admin_dashboard.coupon_code")}
            </li>
          </ul>

          {/* 🔹 Settings */}
          <li
            className='list-group-item'
            onClick={() => handleTabClick("settings")}
          >
            <Icon icon='settings' /> {t("admin_dashboard.settings")}
          </li>
        </ul>
      </div>

      {/* Navigation Bar */}
      <ul className='nav nav-tabs d-none d-lg-flex justify-content-center border-bottom'>
        {/* Dashboard Tab */}
        <li className='nav-item'>
          <button
            className={`nav-link d-flex align-items-center gap-2 ${
              selectedPage === "dashboard"
                ? "active text-primary fw-bold"
                : "text-dark"
            }`}
            onClick={() => handleTabClick("dashboard")}
          >
            <Icon icon='home' /> {t("admin_dashboard.home")}
          </button>
        </li>

        {/* User Management Dropdown */}
        <li className='nav-item dropdown'>
          <button
            className='nav-link dropdown-toggle d-flex align-items-center gap-2 text-dark'
            data-bs-toggle='dropdown'
          >
            <Icon icon='users' /> {t("admin_dashboard.user_management")}
          </button>
          <ul className='dropdown-menu'>
            <li>
              <button
                className='dropdown-item'
                onClick={() => handleTabClick("suppliers")}
              >
                🏭 {t("admin_dashboard.suppliers")}
              </button>
            </li>
            <li>
              <button
                className='dropdown-item'
                onClick={() => handleTabClick("buyers")}
              >
                👥 {t("admin_dashboard.buyers")}
              </button>
            </li>
            <li>
              <button
                className='dropdown-item'
                onClick={() => handleTabClick("admins")}
              >
                🔑 {t("admin_dashboard.admins")}
              </button>
            </li>
          </ul>
        </li>

        {/* Product Management Dropdown */}
        <li className='nav-item dropdown'>
          <button
            className='nav-link dropdown-toggle d-flex align-items-center gap-2 text-dark'
            data-bs-toggle='dropdown'
          >
            <Icon icon='box' /> {t("admin_dashboard.product_management")}
          </button>
          <ul className='dropdown-menu'>
            <li>
              <button
                className='dropdown-item'
                onClick={() => handleTabClick("products")}
              >
                📦 {t("admin_dashboard.products")}
              </button>
            </li>
            <li>
              <button
                className='dropdown-item'
                onClick={() => handleTabClick("product-library")}
              >
                📚 {t("admin_dashboard.product_library")}
              </button>
            </li>
          </ul>
        </li>

        {/* Order Management Dropdown */}
        <li className='nav-item dropdown'>
          <button
            className='nav-link dropdown-toggle d-flex align-items-center gap-2 text-dark'
            data-bs-toggle='dropdown'
          >
            <Icon icon='shopping-bag' /> {t("admin_dashboard.order_management")}
          </button>
          <ul className='dropdown-menu'>
            <li>
              <button
                className='dropdown-item'
                onClick={() => handleTabClick("orders")}
              >
                📦 {t("admin_dashboard.orders")}
              </button>
            </li>
            <li>
              <button
                className='dropdown-item'
                onClick={() => handleTabClick("abandoned-carts")}
              >
                🛑 {t("admin_dashboard.abandoned_carts")}
              </button>
            </li>
          </ul>
        </li>

        {/* Analytics Dropdown */}
        <li className='nav-item dropdown'>
          <button
            className='nav-link dropdown-toggle d-flex align-items-center gap-2 text-dark'
            data-bs-toggle='dropdown'
          >
            <Icon icon='bar-chart-2' /> {t("admin_dashboard.analytics")}
          </button>
          <ul className='dropdown-menu'>
            <li>
              <button
                className='dropdown-item'
                onClick={() => handleTabClick("analytics-overview")}
              >
                📊 {t("admin_dashboard.overview")}
              </button>
            </li>
            <li>
              <button
                className='dropdown-item'
                onClick={() => handleTabClick("live-analytics")}
              >
                📡 {t("admin_dashboard.live_analytics")}
              </button>
            </li>
            <li>
              <button
                className='dropdown-item'
                onClick={() => handleTabClick("analytics-reports")}
              >
                📑 {t("admin_dashboard.reports")}
              </button>
            </li>
          </ul>
        </li>

        {/* Marketing Dropdown */}
        <li className='nav-item dropdown'>
          <button
            className='nav-link dropdown-toggle d-flex align-items-center gap-2 text-dark'
            data-bs-toggle='dropdown'
          >
            <Icon icon='search' /> {t("admin_dashboard.marketing")}
          </button>
          <ul className='dropdown-menu'>
            <li>
              <button
                className='dropdown-item'
                onClick={() => handleTabClick("seo-enhancements")}
              >
                🌍 {t("admin_dashboard.seo_enhancements")}
              </button>
            </li>
            <li>
              <button
                className='dropdown-item'
                onClick={() => handleTabClick("promo-code")}
              >
                🎟 {t("admin_dashboard.promo_code")}
              </button>
            </li>
            <li>
              <button
                className='dropdown-item'
                onClick={() => handleTabClick("coupon-code")}
              >
                💰 {t("admin_dashboard.coupon_code")}
              </button>
            </li>
          </ul>
        </li>

        {/* Settings Tab */}
        <li className='nav-item'>
          <button
            className={`nav-link d-flex align-items-center gap-2 ${
              selectedPage === "settings"
                ? "active text-primary fw-bold"
                : "text-dark"
            }`}
            onClick={() => handleTabClick("settings")}
          >
            <Icon icon='settings' /> {t("admin_dashboard.settings")}
          </button>
        </li>
      </ul>

      {/* Main Content */}
      <div className='mt-4'>{renderContent()}</div>
    </div>
  );
};

export default AdminDashboard;
