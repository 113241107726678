import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase/config";
import { collection, getDocs } from "firebase/firestore";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import ProductCard from "../components/global/ProductCard";

const Home = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const categorySliderRef = useRef(null);
  const navigate = useNavigate();
  const sliderRef = useRef(null); // Ref for controlling the slider

  const { t, i18n } = useTranslation(); // Extract i18n
  const locale = i18n.language; // Current language (e.g., "en" or "ar")
  const currencySymbol = locale === "ar" ? "ر.س." : "SR "; // Currency symbol

  const formatNumber = (number, locale) => {
    return new Intl.NumberFormat(locale, { minimumFractionDigits: 2 }).format(
      number
    );
  };

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products")); // Fetch all products
        const products = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const categoryMap = {};

        products.forEach((product) => {
          if (product.category) {
            // If category is not in the map, create an empty array
            if (!categoryMap[product.category]) {
              categoryMap[product.category] = [];
            }
            // Push product into category array
            categoryMap[product.category].push(product);
          }
        });

        // Extract latest product for each category
        const latestCategories = Object.entries(categoryMap).map(
          ([categoryName, productsInCategory]) => {
            // Sort products in this category by createdAt (latest first)
            productsInCategory.sort(
              (a, b) =>
                (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0)
            );

            const latestProduct = productsInCategory[0]; // Get most recent product
            return {
              name: categoryName,
              imageUrl:
                latestProduct?.mainImageUrl ||
                "https://via.placeholder.com/150", // Use mainImageUrl instead of images array
              slug: categoryName.toLowerCase().replace(/\s+/g, "-"), // Convert category name to slug
            };
          }
        );

        setCategories(latestCategories);
      } catch (error) {
        console.error("Error fetching categories from products:", error);
      }
    };

    fetchCategories();
  }, []);

  // Fetch data from Firestore
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products")); // Replace 'products' with your Firestore collection name
        const productList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productList); // Update state with fetched data
        setLoading(false); // Turn off loading spinner
      } catch (error) {
        console.error("Error fetching products: ", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Disable default arrows to use custom ones
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  const categorySliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 5 } },
      { breakpoint: 992, settings: { slidesToShow: 4 } },
      { breakpoint: 768, settings: { slidesToShow: 3 } },
      { breakpoint: 576, settings: { slidesToShow: 2 } },
      { breakpoint: 400, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div>
      {/* Hero Section */}
      <section
        style={{
          backgroundImage: `url('/banner.webp')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
          padding: "100px 0",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(44, 100, 73, 0.85)",
            zIndex: 1,
          }}
        ></div>

        <div
          style={{
            position: "relative",
            zIndex: 2,
            textAlign: "center",
            padding: "0 15px",
          }}
        >
          <div
            style={{
              maxWidth: "800px",
              margin: "0 auto",
            }}
          >
            <h1 style={{ fontSize: "3rem", fontWeight: "bold" }}>
              {t("hero.hero_title")}
            </h1>
            <p style={{ fontSize: "1.2rem", marginTop: "1.5rem" }}>
              {t("hero.hero_paragraph1")}
            </p>
            <p style={{ fontSize: "1.2rem", marginTop: "1rem" }}>
              {t("hero.hero_paragraph2")}
            </p>
          </div>
        </div>
      </section>

      {/* Category Section */}
      <section className='py-4 bg-white'>
        <h2 className='text-center mb-4'>{t("hero.shop_by_category")}</h2>
        <div className='container'>
          <div className='d-flex justify-content-end align-items-center mb-3'>
            <div className='d-flex align-items-center gap-3'>
              <button
                className='btn btn-outline-dark rounded-circle p-0 d-flex justify-content-center align-items-center'
                style={{ width: "40px", height: "40px" }}
                onClick={() => categorySliderRef.current?.slickPrev()}
              >
                &#8592;
              </button>
              <button
                className='btn btn-outline-dark rounded-circle p-0 d-flex justify-content-center align-items-center'
                style={{ width: "40px", height: "40px" }}
                onClick={() => categorySliderRef.current?.slickNext()}
              >
                &#8594;
              </button>
            </div>
          </div>

          <Slider {...categorySliderSettings} ref={categorySliderRef}>
            {categories.map((category) => (
              <div key={category.name} className='px-2 text-center'>
                <div
                  onClick={() => navigate(`/category/${category.slug}`)}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={category.imageUrl}
                    alt={category.name}
                    className='rounded-circle border border-success'
                    style={{
                      width: "120px",
                      height: "120px",
                      objectFit: "cover",
                      margin: "0 auto",
                    }}
                  />
                  <p className='mt-2 fw-semibold'>{category.name}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* Best Sellers Section */}
      <section
        className='py-4 bg-light'
        style={{ maxWidth: "1200px", margin: "0 auto" }}
      >
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <h2 className='m-0 text-success'>{t("hero.best_sellers")}</h2>
          <div className='d-flex align-items-center gap-3'>
            <span
              className='text-success fw-bold border-bottom border-success pb-1'
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/products")}
            >
              {t("hero.all_products")}
            </span>
            <button
              className='btn btn-outline-success rounded-circle p-0 d-flex justify-content-center align-items-center'
              style={{ width: "40px", height: "40px" }}
              onClick={() => sliderRef.current?.slickPrev()}
            >
              &#8592;
            </button>
            <button
              className='btn btn-outline-success rounded-circle p-0 d-flex justify-content-center align-items-center'
              style={{ width: "40px", height: "40px" }}
              onClick={() => sliderRef.current?.slickNext()}
            >
              &#8594;
            </button>
          </div>
        </div>
        {!loading && products.length > 0 && (
          <Slider {...sliderSettings} ref={sliderRef}>
            {products.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                locale={locale}
                currencySymbol={currencySymbol}
                formatNumber={formatNumber}
              />
            ))}
          </Slider>
        )}
      </section>
    </div>
  );
};

export default Home;
