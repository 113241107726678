import React from "react";
import { AppRoutes } from "./routes"; // Ensure correct import
import { AuthProvider } from "./context/AuthContext";
import { CartProvider } from "./context/CartContext";
import { NotificationProvider } from "./context/NotificationContext";
import { useJsApiLoader } from "@react-google-maps/api";
import LoadingSpinner from "./components/global/LoadingSpinner";

const GOOGLE_MAPS_API_KEY = "AIzaSyD92sPOOqCshhZW-rQdS71XohnOMRqOsG8"; // Replace with actual key
const libraries = ["places"];

const App = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  // ✅ Show Loading Overlay when API is not yet loaded
  if (!isLoaded) {
    return (
      <div className='loading-overlay'>
        <LoadingSpinner /> {/* ✅ Using the existing spinner */}
        <h2 style={{ color: "white", marginTop: "15px" }}>
          Loading Google Maps...
        </h2>
      </div>
    );
  }

  return (
    <AuthProvider>
      <CartProvider>
        <NotificationProvider>
          <AppRoutes googleMapsApiKey={GOOGLE_MAPS_API_KEY} />
        </NotificationProvider>
      </CartProvider>
    </AuthProvider>
  );
};

export default App;
