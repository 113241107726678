import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AdminLogin from "./components/admin/AdminLogin";
import UserLogin from "./components/global/UserLogin";
import AdminDashboard from "./components/admin/AdminDashboard";
import SupplierDashboard from "./components/supplier/SupplierDashboard";
import BuyerDashboard from "./components/buyer/BuyerDashboard";
import PrivateRoute from "./utils/PrivateRoute";
import Layout from "./components/global/Layout";
import Register from "./pages/Register";
import SupplierRegistration from "./components/supplier/SupplierRegistration";
import BuyerRegistration from "./components/buyer/BuyerRegistration";
import Products from "./pages/Products";
import ProductDetails from "./components/global/ProductDetails";
import CartPage from "./components/cart/CartPage";
import CheckoutPage from "./components/checkOut/CheckOutPage";
import PaymentDetailsPage from "./components/checkOut/PaymentDetailsPage";
import PaymentSuccess from "./components/checkOut/PaymentSuccess";
import PaymentFailed from "./components/checkOut/PaymentFailed";
import AdminAddProducts from "./components/admin/AdminAddProducts";
import AdminEditProducts from "./components/admin/AdminEditProducts";
import TermsAndConditions from "./components/global/TermsAndConditions";
import PrivacyPolicy from "./components/global/PrivacyPolicy";
import UpdatedPrivacyPolicy from "./pages/UpdatedPrivacyPolicy";
import UpdatedTermsAndConditions from "./pages/UpdatedTermsAndConditions";
import SupplierProductsPage from "./pages/SupplierProductsPage";
import Categories from "./pages/Categories";
import CategoryDetails from "./pages/CategoryDetails";
import SupplierEditProducts from "./components/supplier/SupplierEditProducts";
import SupplierAddProducts from "./components/supplier/SupplierAddProducts";
import EmployeeLoginPage from "./pages/EmpoyeeLoginPage";
import EmployeeDashboard from "./components/employee/EmployeeDashboard";
import PlasticAndPapersSubcategories from "./pages/PlasticAndPapersSubcategories";
import NotFound from "./pages/NotFound";
import SaudiManufacturedSubcategories from "./pages/SaudiManufacturedSubcategories";
import ConstructionSubcategories from "./pages/ConstructionSubcategories ";
import EquipmentSubcategories from "./pages/EquipmentSubcategories";
import RfqPage from "./pages/RfqPage";
import OrdersPage from "./components/orders/OrdersPage";
import ReviewInvoice from "./components/checkOut/ReviewInvoice";
import RfqChat from "./components/chat/RfqChat";
import CategoryPage from "./pages/CategoryPage";
import SadadPayment from "./components/checkOut/SadadPayment";
import OrderChat from "./components/chat/OrderChat";
import CartChat from "./components/chat/CartChat";
import ProductChat from "./components/chat/ProductChat";

export const AppRoutes = ({ googleMapsApiKey }) => {
  const [userRole, setUserRole] = useState(null);
  const [supplierId, setSupplierId] = useState("");
  return (
    <Routes>
      {/* Unprotected Route */}
      <Route
        path='/'
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
      <Route
        path='/404'
        element={
          <Layout>
            <NotFound />
          </Layout>
        }
      />
      <Route
        path='/payment-success'
        element={
          <Layout>
            <PaymentSuccess />
          </Layout>
        }
      />
      <Route
        path='/payment-failed'
        element={
          <Layout>
            <PaymentFailed />
          </Layout>
        }
      />
      <Route
        path='/terms-and-conditions'
        element={
          <Layout>
            <TermsAndConditions />
          </Layout>
        }
      />
      <Route
        path='/updated-terms-and-conditions'
        element={
          <Layout>
            <UpdatedTermsAndConditions />
          </Layout>
        }
      />
      <Route
        path='/privacy-policy'
        element={
          <Layout>
            <PrivacyPolicy />
          </Layout>
        }
      />
      <Route
        path='/updated-privacy-policy'
        element={
          <Layout>
            <UpdatedPrivacyPolicy />
          </Layout>
        }
      />
      <Route
        path='/user-login'
        element={
          <Layout>
            <UserLogin />
          </Layout>
        }
      />
      <Route
        path='/register'
        element={
          <Layout>
            <Register />
          </Layout>
        }
      />
      <Route
        path='/supplier'
        element={
          <Layout>
            <SupplierRegistration />
          </Layout>
        }
      />
      <Route
        path='/buyer'
        element={
          <Layout>
            <BuyerRegistration />
          </Layout>
        }
      />
      <Route
        path='/products'
        element={
          <Layout>
            <Products />
          </Layout>
        }
      />
      <Route
        path='/product/:id'
        element={
          <Layout>
            <ProductDetails />
          </Layout>
        }
      />
      <Route
        path='/categories'
        element={
          <Layout>
            <Categories />
          </Layout>
        }
      />

      <Route
        path='/category/:slug'
        element={
          <Layout>
            <CategoryPage />
          </Layout>
        }
      />

      <Route
        path='/construction'
        element={
          <Layout>
            <ConstructionSubcategories />
          </Layout>
        }
      />
      <Route
        path='/equipment'
        element={
          <Layout>
            <EquipmentSubcategories />
          </Layout>
        }
      />
      <Route
        path='/plastic-and-papers'
        element={
          <Layout>
            <PlasticAndPapersSubcategories />
          </Layout>
        }
      />
      <Route
        path='/saudi-manufactured'
        element={
          <Layout>
            <SaudiManufacturedSubcategories />
          </Layout>
        }
      />
      <Route
        path='/categories/:id'
        element={
          <Layout>
            <CategoryDetails />
          </Layout>
        }
      />
      <Route
        path='/admin-login'
        element={
          <Layout>
            <AdminLogin />
          </Layout>
        }
      />
      <Route
        path='/employee-login'
        element={
          <Layout>
            <EmployeeLoginPage
              setUserRole={setUserRole}
              setSupplierId={setSupplierId}
            />
          </Layout>
        }
      />
      <Route
        path='/employee-dashboard'
        element={
          <Layout>
            <EmployeeDashboard userRole={userRole} supplierId={supplierId} />
          </Layout>
        }
      />

      {/* Protected Routes */}
      {/* ADMIN */}
      <Route
        path='/admin-dashboard'
        element={
          <Layout>
            <PrivateRoute role='admin'>
              <AdminDashboard />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/admin-add-products'
        element={
          <Layout>
            <PrivateRoute role='admin'>
              <AdminAddProducts />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/admin-edit-products/:productId'
        element={
          <Layout>
            <PrivateRoute role='admin'>
              <AdminEditProducts />
            </PrivateRoute>
          </Layout>
        }
      />

      {/* SUPPLIER */}
      <Route
        path='/supplier-dashboard'
        element={
          <Layout>
            <PrivateRoute role='supplier'>
              <SupplierDashboard />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/supplier/:supplierId'
        element={
          <Layout>
            <SupplierProductsPage />
          </Layout>
        }
      />
      <Route
        path='/supplier-add-products'
        element={
          <Layout>
            <PrivateRoute role='supplier'>
              <SupplierAddProducts />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/supplier-edit-products/:productId'
        element={
          <Layout>
            <PrivateRoute role='supplier'>
              <SupplierEditProducts />
            </PrivateRoute>
          </Layout>
        }
      />

      {/* BUYER */}
      <Route
        path='/cart'
        element={
          <Layout>
            <PrivateRoute role='buyer'>
              <CartPage />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/checkout'
        element={
          <Layout>
            <PrivateRoute role='buyer'>
              <CheckoutPage googleMapsApiKey={googleMapsApiKey} />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/buyer-dashboard'
        element={
          <Layout>
            <PrivateRoute role='buyer'>
              <BuyerDashboard />
            </PrivateRoute>
          </Layout>
        }
      />

      {/* SUPPLIER AND BUYER */}

      <Route
        path='/review-invoice/:billNumber'
        element={
          <Layout>
            <ReviewInvoice />
          </Layout>
        }
      />

      <Route
        path='/payment-details'
        element={
          <Layout>
            <PaymentDetailsPage />
          </Layout>
        }
      />

      <Route
        path='/orders'
        element={
          <Layout>
            <OrdersPage />
          </Layout>
        }
      />

      <Route
        path='/order-chat/:chatId'
        element={
          <Layout>
            <OrderChat />
          </Layout>
        }
      />

      <Route
        path='/sadad-confirmation/:billNumber'
        element={
          <Layout>
            <SadadPayment />
          </Layout>
        }
      />

      <Route
        path='/rfq-chat/:chatId'
        element={
          <Layout>
            <RfqChat />
          </Layout>
        }
      />

      <Route
        path='/cart-chat/:chatId'
        element={
          <Layout>
            <CartChat />
          </Layout>
        }
      />

      <Route
        path='/product-chat/:chatId'
        element={
          <Layout>
            <ProductChat />
          </Layout>
        }
      />

      <Route
        path='/rfq-chat/:chatId'
        element={
          <Layout>
            <RfqChat />
          </Layout>
        }
      />

      <Route
        path='/rfq'
        element={
          <Layout>
            <PrivateRoute roles={["supplier", "buyer"]}>
              <RfqPage />
            </PrivateRoute>
          </Layout>
        }
      />

      <Route
        path='/checkout'
        element={
          <Layout>
            <CheckoutPage />
          </Layout>
        }
      />
    </Routes>
  );
};
