import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../firebase/config";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ChevronLeft, ChevronRight } from "react-feather";
import Notification from "./Notification";

import { useNavigate } from "react-router-dom";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState("1");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    title: "",
    message: "",
  });

  const showNotification = (title, message) => {
    setNotificationContent({ title, message });
    setIsNotificationOpen(true);
  };

  const navigate = useNavigate();

  const handleContactSupplier = () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      showNotification(
        "Login Required",
        "Please log in to contact the supplier."
      );
      return;
    }

    if (!product?.supplierId) {
      showNotification("Missing Info", "Supplier ID is missing.");
      return;
    }

    const newChatId = `${currentUser.uid}_${product.supplierId}_${id}`;

    // ✅ Redirect to the chat route
    navigate(`/product-chat/${newChatId}`, {
      state: {
        productId: id,
        supplierId: product.supplierId,
      },
    });
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productRef = doc(db, "products", id);
        const docSnap = await getDoc(productRef);

        if (docSnap.exists()) {
          const productData = docSnap.data();

          // Helper to format price ranges
          const formatRange = (min, max) => {
            if (max) {
              return `${min} - ${max} pieces`;
            }
            return `${min}+ pieces`;
          };

          const uniqueLocations = Array.from(
            new Set(
              (productData.priceRanges || [])
                .flatMap((r) => r.locations || [])
                .map((locObj) =>
                  typeof locObj?.location === "string"
                    ? locObj.location.trim()
                    : ""
                )
            )
          ).filter(Boolean);

          // Map priceRanges to formatted display
          const mappedTiers = (productData.priceRanges || []).map((range) => ({
            range: formatRange(range.minQty, range.maxQty),
            price: range.price,
            minQty: range.minQty,
            maxQty: range.maxQty,
            locations: (range.locations || [])
              .map((locObj) =>
                typeof locObj?.location === "string"
                  ? locObj.location.toLowerCase().trim()
                  : ""
              )
              .filter(Boolean),
            locationsData: range.locations || [], // 🔥 raw location objects with price
          }));

          // Set the product state
          setProduct({
            ...productData,
            name: productData.productName || "Product Name",
            mainImage:
              productData.mainImageUrl ||
              "https://via.placeholder.com/400x400?text=Main+Image",
            additionalImageUrls: productData.additionalImageUrls || [],
            category: productData.category || "Uncategorized",
            subcategory: productData.subCategory || "",
            supplierName: productData.supplierName || "Unknown Supplier",
            priceTiers: mappedTiers,
            locations: uniqueLocations,
            sizes: productData.sizes || [],
            colors: productData.colors || [],
            origin: productData.mainLocation || "Origin not specified",
            companyDescription:
              productData.companyDescription || "No description available.",
          });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    if (id) fetchProduct();
  }, [id]);

  const handleBlur = () => {
    const num = parseInt(quantity, 10);
    const firstMinQty = parseInt(product?.priceTiers?.[0]?.minQty || "1", 10);

    if (isNaN(num) || num < 1) {
      setQuantity(`${firstMinQty > 0 ? firstMinQty : 1}`);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Allow empty string to let user delete
    if (value === "") {
      setQuantity("");
    } else if (/^\d+$/.test(value)) {
      setQuantity(value);
    }
  };

  const getShippingCost = () => {
    const selectedLoc = deliveryLocation.trim().toLowerCase();

    for (const tier of product.priceTiers || []) {
      const match = (tier.locationsData || []).find(
        (locObj) =>
          typeof locObj?.location === "string" &&
          locObj.location.toLowerCase().trim() === selectedLoc
      );

      if (match) {
        return match.locationPrice ?? 0;
      }
    }

    return 0;
  };

  if (!product) return <div className='container py-5'>Loading...</div>;

  // Group price tiers into rows of two
  const groupedTiers = [];
  if (product?.priceTiers?.length) {
    for (let i = 0; i < product.priceTiers.length; i += 2) {
      groupedTiers.push(product.priceTiers.slice(i, i + 2));
    }
  }

  const getMatchedPriceTier = () => {
    const qty = parseInt(quantity || "0", 10);

    if (!product?.priceTiers?.length || isNaN(qty)) return null;

    // Sort by minQty ascending for consistent tier matching
    const sortedTiers = [...product.priceTiers].sort(
      (a, b) => a.minQty - b.minQty
    );

    for (const tier of sortedTiers) {
      const { minQty, maxQty } = tier;

      const isUnlimited =
        maxQty === undefined ||
        maxQty === null ||
        maxQty.toString().toLowerCase() === "unlimited";

      const inRange = isUnlimited
        ? qty >= minQty
        : qty >= minQty && qty <= parseInt(maxQty, 10);

      if (inRange) return tier;
    }

    return null;
  };

  const getPricePerUnit = () => {
    const matched = getMatchedPriceTier();
    return matched ? parseFloat(matched.price) : 0;
  };

  const safeQty = parseInt(quantity || "0", 10);

  const shippingCost = getShippingCost();
  const pricePerUnit = getPricePerUnit();

  const subtotal =
    isNaN(pricePerUnit) || isNaN(safeQty) || safeQty < 1
      ? 0
      : pricePerUnit * safeQty;

  const safeShipping = isNaN(shippingCost) ? 0 : shippingCost;

  const isInvalidState = () => {
    const qty = parseInt(quantity);
    const invalidQty = !quantity || quantity === "0" || isNaN(qty) || qty <= 0;

    const matchedTier = getMatchedPriceTier();

    const invalidPrice =
      !matchedTier || isNaN(matchedTier.price) || matchedTier.price <= 0;

    const invalidShipping = isNaN(getShippingCost());

    return (
      !selectedColor ||
      !selectedSize ||
      !deliveryLocation ||
      invalidQty ||
      invalidPrice ||
      invalidShipping
    );
  };

  const CustomPrevArrow = ({ onClick }) => (
    <button
      className='simple-arrow left'
      onClick={onClick}
      aria-label='Previous'
    >
      <ChevronLeft size={32} color='#2c6449' />
    </button>
  );

  const CustomNextArrow = ({ onClick }) => (
    <button className='simple-arrow right' onClick={onClick} aria-label='Next'>
      <ChevronRight size={32} color='#2c6449' />
    </button>
  );

  const handleAddToCart = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      showNotification("Login Required", "Please log in to add items to cart.");
      return;
    }

    const qty = parseInt(quantity);
    if (!qty || qty <= 0 || isNaN(qty)) {
      showNotification("Invalid Quantity", "Please enter a valid quantity.");
      return;
    }

    if (!selectedColor || !selectedSize || !deliveryLocation) {
      showNotification(
        "Missing Fields",
        "Please fill all options (color, size, location)."
      );
      return;
    }

    const cartId = uuidv4();
    const userId = currentUser.uid;

    const cartItem = {
      cartId,
      productId: id,
      name: product.name,
      mainImageUrl: product.mainImage,
      price: pricePerUnit,
      quantity: qty,
      color: selectedColor,
      size: selectedSize,
      shippingCost: shippingCost,
      deliveryLocation: deliveryLocation,
      supplierName: product.supplierName,
      supplierId: product.supplierId || null,
      addedAt: Timestamp.now(),
      buyerId: userId,
    };

    try {
      const cartRef = doc(db, "carts", userId);
      const cartSnap = await getDoc(cartRef);

      if (cartSnap.exists()) {
        await updateDoc(cartRef, {
          items: [...cartSnap.data().items, cartItem],
          updatedAt: serverTimestamp(),
        });
      } else {
        await setDoc(cartRef, {
          buyerId: userId,
          items: [cartItem],
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
      }

      showNotification("Success", "Product added to cart!");
    } catch (error) {
      showNotification("Error", "Failed to add to cart. Please try again.");
    }
  };

  return (
    <div className='container py-5'>
      {/* Breadcrumb Navigation */}
      <div className='mb-4 small' style={{ color: "rgba(44, 100, 73, 0.8)" }}>
        <span className='text-uppercase fw-semibold'>{product.category}</span>
        {product.subcategory && (
          <>
            {" / "}
            <span className='text-uppercase fw-semibold'>
              {product.subcategory}
            </span>
          </>
        )}
      </div>

      <div className='row g-4'>
        {/* Left Column - Images */}
        <div className='col-md-6 d-flex align-items-center'>
          <div className='w-100'>
            <div className='mb-3 px-3'>
              <div className='position-relative px-4'>
                <Slider
                  arrows
                  dots={false}
                  autoplay
                  autoplaySpeed={3000}
                  infinite
                  speed={500}
                  slidesToShow={1}
                  slidesToScroll={1}
                  prevArrow={<CustomPrevArrow />}
                  nextArrow={<CustomNextArrow />}
                >
                  {[product.mainImage, ...product.additionalImageUrls].map(
                    (img, index) => (
                      <div key={index} className='text-center'>
                        <img
                          src={img}
                          alt={`Slide ${index}`}
                          className='img-fluid shadow-sm rounded'
                          style={{
                            width: "100%",
                            maxWidth: "450px",
                            height: "auto",
                            objectFit: "contain",
                            margin: "0 auto",
                          }}
                        />
                      </div>
                    )
                  )}
                </Slider>
              </div>
            </div>

            <div className='d-flex gap-2 justify-content-center'>
              {product?.additionalImageUrls?.map((url, idx) => (
                <img
                  key={idx}
                  src={url}
                  alt={`Thumbnail ${idx + 1}`}
                  className='img-thumbnail'
                  style={{ width: "80px", cursor: "pointer" }}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Right Column - Product Info */}
        <div className='col-md-6'>
          {product?.category && (
            <div className='badge bg-success mb-2 text-uppercase'>
              {product.category}
            </div>
          )}

          <h2 className='h2'>{product.name}</h2>

          {product?.supplierName && (
            <div className='text-muted mb-2'>
              <strong className='text-success'>{product.supplierName}</strong>
            </div>
          )}

          {/* Pricing */}
          <div className='mb-4'>
            <h6 className='fw-bold mb-2'>Pricing:</h6>
            {groupedTiers.map((pair, idx) => (
              <div key={idx} className='row mb-2'>
                {pair.map((tier, i) => (
                  <div key={i} className='col-md-6'>
                    <div className='d-flex justify-content-between px-3 py-2 border rounded bg-light'>
                      <span>{tier.range}</span>
                      <span className='fw-bold'>{tier.price}</span>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          {/* Quantity Control */}
          <div className='mb-3'>
            <div className='d-flex align-items-center flex-wrap gap-3 mb-2'>
              <div className='d-flex align-items-center'>
                <span className='me-2'>Quantity</span>
                <button
                  onClick={() =>
                    setQuantity(
                      (prev) => `${Math.max(parseInt(prev || "1", 10) - 1, 1)}`
                    )
                  }
                  className='btn btn-outline-secondary btn-sm me-2'
                >
                  −
                </button>
                <input
                  type='text'
                  inputMode='numeric'
                  value={quantity}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  className='form-control form-control-sm text-center'
                  style={{
                    width: "60px",
                    MozAppearance: "textfield",
                    WebkitAppearance: "none",
                  }}
                />
                <button
                  onClick={() =>
                    setQuantity((prev) => `${parseInt(prev || "1", 10) + 1}`)
                  }
                  className='btn btn-outline-secondary btn-sm ms-2'
                >
                  +
                </button>
              </div>

              {/* Subtotal and Shipping */}
              <div className='d-flex align-items-center gap-4 fs-5 fw-semibold text-dark'>
                <div>
                  <span>Subtotal:</span> {subtotal.toFixed(2)} SR
                </div>
                <div>
                  <span>Shipping:</span> {safeShipping.toFixed(2)} SR
                </div>
              </div>
            </div>
          </div>

          {/* Color, Size, and Delivery Location in 1 Row */}
          <div className='row mb-3'>
            {/* Color */}
            <div className='col-md-4'>
              <label htmlFor='color-select' className='form-label fw-bold'>
                Color:
              </label>
              <select
                id='color-select'
                className='form-select form-select-sm'
                value={selectedColor}
                onChange={(e) => setSelectedColor(e.target.value)}
              >
                <option value=''>Select Color</option>
                {product.colors?.map((color, idx) => (
                  <option key={idx} value={color}>
                    {color.charAt(0).toUpperCase() + color.slice(1)}
                  </option>
                ))}
              </select>
            </div>

            {/* Size */}
            <div className='col-md-4'>
              <label htmlFor='size-select' className='form-label fw-bold'>
                Size:
              </label>
              <select
                id='size-select'
                className='form-select form-select-sm'
                value={selectedSize}
                onChange={(e) => setSelectedSize(e.target.value)}
              >
                <option value=''>Select Size</option>
                {product.sizes?.map((size, idx) => (
                  <option key={idx} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>

            {/* Delivery Location */}
            <div className='col-md-4'>
              <label htmlFor='location-select' className='form-label fw-bold'>
                Delivery Location:
              </label>
              <select
                id='location-select'
                className='form-select form-select-sm'
                value={deliveryLocation}
                onChange={(e) => setDeliveryLocation(e.target.value)}
              >
                <option value=''>Select Location</option>
                {product.locations?.map((loc, idx) => (
                  <option key={idx} value={loc}>
                    {loc}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Action Buttons */}
          <div className='d-flex flex-column gap-2 mb-2'>
            <div className='d-flex gap-2'>
              <button
                className={`btn fw-bold px-4 ${
                  isInvalidState() ? "btn-danger disabled" : "btn-dark"
                }`}
                disabled={isInvalidState()}
                onClick={handleAddToCart}
              >
                Add to Cart
              </button>

              <button
                className='btn fw-bold px-4'
                style={{
                  backgroundColor: "#2c6449",
                  color: "white",
                  border: "none",
                }}
                onClick={handleContactSupplier}
              >
                Contact Supplier
              </button>
            </div>

            {isInvalidState() && (
              <small className='text-danger mt-1'>
                Please fill out all fields correctly or{" "}
                <strong>contact the supplier to finalize your order</strong>.
              </small>
            )}
          </div>

          {/* Tabs Section */}
          <ul
            className='nav nav-tabs mb-3'
            id='productTabs'
            role='tablist'
            style={{
              borderBottom: "2px solid #dee2e6",
            }}
          >
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link active'
                id='desc-tab'
                data-bs-toggle='tab'
                data-bs-target='#desc'
                type='button'
                role='tab'
                aria-controls='desc'
                aria-selected='true'
                style={{ fontWeight: "bold", color: "#2c6449" }}
              >
                Description
              </button>
            </li>
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link'
                id='origin-tab'
                data-bs-toggle='tab'
                data-bs-target='#origin'
                type='button'
                role='tab'
                aria-controls='origin'
                aria-selected='false'
                style={{ fontWeight: "bold", color: "#2c6449" }}
              >
                Origin
              </button>
            </li>
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link'
                id='supplier-tab'
                data-bs-toggle='tab'
                data-bs-target='#supplier'
                type='button'
                role='tab'
                aria-controls='supplier'
                aria-selected='false'
                style={{ fontWeight: "bold", color: "#2c6449" }}
              >
                About the Supplier
              </button>
            </li>
          </ul>

          <div className='tab-content' id='productTabsContent'>
            {/* Description Tab */}
            <div
              className='tab-pane fade show active'
              id='desc'
              role='tabpanel'
              aria-labelledby='desc-tab'
            >
              <p>{product.description}</p>
              <ul>
                {product?.highlights?.map((highlight, idx) => (
                  <li key={idx}>{highlight}</li>
                ))}
              </ul>
            </div>

            {/* Origin Tab */}
            <div
              className='tab-pane fade'
              id='origin'
              role='tabpanel'
              aria-labelledby='origin-tab'
            >
              <p>{product.origin}</p>
            </div>

            {/* Supplier Tab */}
            <div
              className='tab-pane fade'
              id='supplier'
              role='tabpanel'
              aria-labelledby='supplier-tab'
            >
              <p>{product.companyDescription}</p>
            </div>
          </div>
        </div>
      </div>
      <Notification
        isOpen={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false)}
        title={notificationContent.title}
        message={notificationContent.message}
        duration={2000}
      />
    </div>
  );
};

export default ProductDetails;
