import React, { useState } from "react";
import styled from "styled-components";
import BuyerProfile from "../buyer/BuyerProfile";
import { useAuth } from "../../context/AuthContext";
import OrdersPage from "../orders/OrdersPage";

import BuyerMessages from "./BuyerMessages";

// Styled-components for Sidebar with Parallax Effect
const Sidebar = styled.div`
  background-color: #f8f9fa;
  transition: all 0.5s ease-in-out;
  transform: ${({ visible }) =>
    visible ? "translateY(0)" : "translateY(-20%)"};
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  max-height: ${({ visible }) => (visible ? "100vh" : "0")};
  overflow: hidden;
  padding: ${({ visible }) => (visible ? "10px 20px" : "0")};
`;

const SidebarItem = styled.button`
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  border: none;
  background: none;
  font-size: 16px;
  color: ${({ active }) => (active ? "#2c6449" : "inherit")};
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    color: #2c6449;
  }
`;

const Navbar = styled.nav`
  background-color: Transparent;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 20px;
`;

const HamburgerButton = styled.button`
  border: none;
  background: none;
  color: #2c6449;
  cursor: pointer;

  &:hover {
    color: #14532d;
  }
`;

const Dashboard = () => {
  const { userData } = useAuth();
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState("home");

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const menuItems = [
    { name: "home", icon: "🏠" },
    { name: "profile", icon: "👤" },
    { name: "orders", icon: "🛒" },
    { name: "wishlist", icon: "❤️" },
    { name: "cart", icon: "🛍️" },
    { name: "messages", icon: "✉️" },
    { name: "support", icon: "❓" },
  ];

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
    setSidebarVisible(false); // Auto-close sidebar after selecting a menu item
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case "home":
        return (
          <div>
            <h4>Welcome, {userData?.name || "Buyer"}!</h4>
            <p>Here you can manage your orders, wishlist, and more.</p>
          </div>
        );
      case "orders":
        return <OrdersPage />;
      case "wishlist":
        return (
          <div>
            <h4>Wishlist</h4>
            <p>Items you have added to your wishlist.</p>
          </div>
        );
      case "cart":
        return (
          <div>
            <h4>Shopping Cart</h4>
            <p>Review items in your shopping cart.</p>
          </div>
        );
      case "messages":
        return <BuyerMessages />;
      case "profile":
        return <BuyerProfile />;
      case "support":
        return (
          <div>
            <h4>Support</h4>
            <p>Get help and support.</p>
          </div>
        );
      default:
        return <h1>Welcome to your Buyer Dashboard!</h1>;
    }
  };

  return (
    <div className='container-fluid'>
      <Navbar className='row'>
        <div className='col-6 d-flex align-items-center'>
          <HamburgerButton onClick={toggleSidebar}>
            <span>☰</span>
          </HamburgerButton>
          <h5 className='ms-2 mb-0'>Buyer Dashboard</h5>
        </div>
        <div className='col-6 d-flex justify-content-end align-items-center'>
          <img
            src={userData?.logoUrl || "https://via.placeholder.com/32"}
            alt='User Avatar'
            style={{ borderRadius: "50%", width: "60px", height: "60px" }}
          />
        </div>
      </Navbar>

      <div className='row'>
        {isSidebarVisible && (
          <div className='col-md-2'>
            <Sidebar visible={isSidebarVisible}>
              <ul style={{ padding: 0, margin: 0, listStyle: "none" }}>
                {menuItems.map((menu) => (
                  <li key={menu.name}>
                    <SidebarItem
                      active={selectedMenu === menu.name}
                      onClick={() => handleMenuClick(menu.name)}
                    >
                      <span>{menu.icon}</span>
                      {menu.name.charAt(0).toUpperCase() + menu.name.slice(1)}
                    </SidebarItem>
                  </li>
                ))}
              </ul>
            </Sidebar>
          </div>
        )}
        <div className={isSidebarVisible ? "col-md-10" : "col-12"}>
          <div style={{ padding: "20px" }}>{renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
