import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProductCard = ({ product, locale, currencySymbol, formatNumber }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const priceRanges = product.priceRanges || [];
  const prices = priceRanges.map((range) => range.price);
  const lowestPrice = prices.length ? Math.min(...prices) : "N/A";
  const highestPrice = prices.length ? Math.max(...prices) : "N/A";
  const minOrder = priceRanges[0]?.minQty || "N/A";
  const mainImage = product.mainImageUrl || "https://via.placeholder.com/300";
  const category = product.category || t("uncategorized");

  return (
    <div className='p-2'>
      <div
        className='card shadow-sm border-0 h-100 d-flex flex-column'
        style={{ minHeight: "350px" }}
      >
        {/* Product Image */}
        <div
          className='card-img-top overflow-hidden'
          style={{ height: "200px" }}
        >
          <img
            src={mainImage}
            alt={product.description || t("hero.unnamed_product")}
            className='img-fluid w-100 h-100'
            style={{ objectFit: "cover" }}
          />
        </div>

        {/* Product Details */}
        <div className='card-body d-flex flex-column'>
          <div>
            <p className='text-muted small mb-1 text-start'>{category}</p>
            <h5 className='card-title mb-2 text-truncate text-capitalize text-start fw-bold'>
              {product.productName || t("hero.unnamed_product")}
            </h5>

            {/* Price Range (Now Colored `#2c6449` and NOT Bold) */}
            <p
              className='text-start mb-1'
              style={{ fontSize: "14px", color: "#2c6449", lineHeight: "1.2" }}
            >
              {/^[0-9]+(\.[0-9]+)?$/.test(lowestPrice) &&
              /^[0-9]+(\.[0-9]+)?$/.test(highestPrice)
                ? `${currencySymbol}${formatNumber(
                    lowestPrice,
                    locale
                  )} - ${currencySymbol}${formatNumber(highestPrice, locale)}`
                : "Pricing - Refer to Supplier"}
            </p>
            <p className='text-muted small text-start mb-1'>
              {t("hero.min_order", { minOrder })}
            </p>
          </div>

          {/* Action Buttons (Side by Side, Smaller, No Excess Space) */}
          <div className='d-flex gap-2 mt-1'>
            <button
              onClick={() => navigate(`/product/${product.id}`)}
              className='btn btn-success w-50'
              style={{ fontSize: "12px", padding: "4px 6px" }} // Smaller Font & Padding
            >
              {t("hero.view_details")}
            </button>
            <button
              className='btn btn-primary w-50'
              style={{ fontSize: "12px", padding: "4px 6px" }} // Smaller Font & Padding
            >
              {t("hero.contact_supplier")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
