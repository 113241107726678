import React, { useEffect, useState } from "react";
import {
  doc,
  getDoc,
  setDoc, // ✅ ADD THIS
  updateDoc,
  onSnapshot,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../firebase/config";
import Notification from "../global/Notification";

const MiniProductDetails = ({ productId, supplierId }) => {
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState("1");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    title: "",
    message: "",
  });

  const auth = getAuth();
  const currentUser = auth.currentUser;

  const showNotification = (title, message) => {
    setNotificationContent({ title, message });
    setIsNotificationOpen(true);
  };

  const isSupplier = currentUser?.uid === supplierId;

  useEffect(() => {
    if (!productId) return;

    const productRef = doc(db, "products", productId);
    const unsubscribe = onSnapshot(productRef, (snap) => {
      if (snap.exists()) {
        setProduct(snap.data());
      }
    });

    return () => unsubscribe();
  }, [productId]);

  const getShippingCost = () => {
    const selectedLoc = deliveryLocation.trim().toLowerCase();
    for (const tier of product?.priceRanges || []) {
      const match = (tier.locations || []).find(
        (locObj) => locObj.location?.toLowerCase().trim() === selectedLoc
      );
      if (match) return match.locationPrice ?? 0;
    }
    return 0;
  };

  const getMatchedPriceTier = () => {
    const qty = parseInt(quantity, 10);
    return (product?.priceRanges || []).find((tier) => {
      const isUnlimited = !tier.maxQty;
      return isUnlimited
        ? qty >= tier.minQty
        : qty >= tier.minQty && qty <= tier.maxQty;
    });
  };

  const handleAddToCart = async () => {
    if (!currentUser)
      return showNotification("Login Required", "Please login to add to cart");
    const matchedTier = getMatchedPriceTier();
    const price = matchedTier?.price || 0;
    const shipping = getShippingCost();
    const qty = parseInt(quantity);

    if (
      !selectedColor ||
      !selectedSize ||
      !deliveryLocation ||
      !price ||
      !qty
    ) {
      return showNotification("Missing Details", "Please complete all fields.");
    }

    const cartRef = doc(db, "carts", currentUser.uid);
    const cartSnap = await getDoc(cartRef);
    const cartItem = {
      productId,
      name: product.productName,
      mainImageUrl: product.mainImageUrl,
      price,
      quantity: qty,
      color: selectedColor,
      size: selectedSize,
      shippingCost: shipping,
      deliveryLocation,
      supplierId,
      addedAt: Timestamp.now(),
      buyerId: currentUser.uid,
    };

    try {
      if (cartSnap.exists()) {
        await updateDoc(cartRef, {
          items: [...(cartSnap.data().items || []), cartItem],
          updatedAt: serverTimestamp(),
        });
      } else {
        await setDoc(cartRef, {
          buyerId: currentUser.uid,
          items: [cartItem],
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
      }
      showNotification("Success", "Item added to cart.");
    } catch (err) {
      console.error("Add to cart error:", err);
      showNotification("Error", "Failed to add to cart.");
    }
  };

  const handlePriceChange = (tierIndex, newValue) => {
    if (!isSupplier) return;
    const updatedRanges = [...product.priceRanges];
    updatedRanges[tierIndex].price = parseFloat(newValue) || 0;
    updateDoc(doc(db, "products", productId), {
      priceRanges: updatedRanges,
    });
  };

  const handleShippingChange = (tierIndex, locIndex, newValue) => {
    if (!isSupplier) return;
    const updatedRanges = [...product.priceRanges];
    updatedRanges[tierIndex].locations[locIndex].locationPrice =
      parseFloat(newValue) || 0;
    updateDoc(doc(db, "products", productId), {
      priceRanges: updatedRanges,
    });
  };

  if (!product) return <p>Loading product info...</p>;

  return (
    <div className='border rounded p-3 mb-3'>
      <h5>{product.productName}</h5>
      <img
        src={product.mainImageUrl || "https://via.placeholder.com/150"}
        alt={product.productName}
        className='img-thumbnail mb-2'
        style={{ maxWidth: "120px" }}
      />
      <div className='mb-2'>
        <strong>Price Tiers:</strong>
        {(product.priceRanges || []).map((tier, tierIndex) => (
          <div key={tierIndex} className='d-flex gap-2 align-items-center'>
            <span>
              {tier.minQty} - {tier.maxQty || "∞"} pcs:
            </span>
            {isSupplier ? (
              <input
                type='number'
                className='form-control form-control-sm'
                value={tier.price}
                style={{ width: "80px" }}
                onChange={(e) => handlePriceChange(tierIndex, e.target.value)}
              />
            ) : (
              <span className='text-success'>SAR {tier.price}</span>
            )}
          </div>
        ))}
      </div>
      <div className='mb-2'>
        <strong>Shipping Prices:</strong>
        {(product.priceRanges || []).flatMap((tier, tierIndex) =>
          (tier.locations || []).map((loc, locIndex) => (
            <div
              key={`${tierIndex}-${locIndex}`}
              className='d-flex gap-2 align-items-center'
            >
              <span>{loc.location}:</span>
              {isSupplier ? (
                <input
                  type='number'
                  className='form-control form-control-sm'
                  value={loc.locationPrice}
                  style={{ width: "80px" }}
                  onChange={(e) =>
                    handleShippingChange(tierIndex, locIndex, e.target.value)
                  }
                />
              ) : (
                <span className='text-muted'>SAR {loc.locationPrice}</span>
              )}
            </div>
          ))
        )}
      </div>

      <div className='row'>
        <div className='col-4'>
          <select
            className='form-select form-select-sm mb-2'
            value={selectedColor}
            onChange={(e) => setSelectedColor(e.target.value)}
          >
            <option value=''>Color</option>
            {product.colors?.map((color, i) => (
              <option key={i} value={color}>
                {color}
              </option>
            ))}
          </select>
        </div>
        <div className='col-4'>
          <select
            className='form-select form-select-sm mb-2'
            value={selectedSize}
            onChange={(e) => setSelectedSize(e.target.value)}
          >
            <option value=''>Size</option>
            {product.sizes?.map((size, i) => (
              <option key={i} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className='col-4'>
          <select
            className='form-select form-select-sm mb-2'
            value={deliveryLocation}
            onChange={(e) => setDeliveryLocation(e.target.value)}
          >
            <option value=''>Location</option>
            {Array.from(
              new Set(
                (product.priceRanges || [])
                  .flatMap((r) => r.locations || [])
                  .map((l) => l.location)
              )
            ).map((loc, i) => (
              <option key={i} value={loc}>
                {loc}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='input-group input-group-sm mb-2'>
        <span className='input-group-text'>Qty</span>
        <input
          type='number'
          min='1'
          className='form-control'
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
      </div>
      <button
        className='btn btn-success btn-sm w-100'
        onClick={handleAddToCart}
      >
        Add to Cart
      </button>

      <Notification
        isOpen={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false)}
        title={notificationContent.title}
        message={notificationContent.message}
        duration={2000}
      />
    </div>
  );
};

export default MiniProductDetails;
