import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase/config";
import Icon from "feather-icons-react";
import { useAuth } from "../../context/AuthContext"; // Import useAuth hook
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";

const Products = () => {
  const { hasRole, loading, role } = useAuth(); // Access role and loading state
  const [productData, setProductData] = useState([]);
  const [categories, setCategories] = useState([]); // Unique categories
  const [selectedTab, setSelectedTab] = useState("All"); // Default tab
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const itemsPerPage = 5; // Number of items per page
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [filterType, setFilterType] = useState("manual"); // Dropdown selection
  const [searchTerm, setSearchTerm] = useState(""); // Search input

  useEffect(() => {
    // Ensure only admin users can fetch data
    if (!loading && hasRole("admin")) {
      const fetchProducts = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, "products"));
          const products = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setProductData(products);

          // Extract unique categories
          const uniqueCategories = [
            "All",
            ...new Set(
              products.map((product) => product.category || "Uncategorized")
            ),
          ];
          setCategories(uniqueCategories);
        } catch (error) {
          console.error("Error fetching products: ", error);
        }
      };

      fetchProducts();
    }
  }, [loading, hasRole]);

  useEffect(() => {
    console.log("Updated Product Data:", productData);
  }, [productData]);

  const handleDelete = async (productId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "products", productId));
        setProductData((prev) =>
          prev.filter((product) => product.id !== productId)
        );
        alert("Product deleted successfully!");
      } catch (error) {
        console.error("Error deleting product:", error);
        alert("Failed to delete product.");
      }
    }
  };

  // Filter products based on selected tab
  const filteredProducts =
    selectedTab === "All"
      ? productData
      : productData.filter(
          (product) =>
            product.category?.trim().toLowerCase() ===
            selectedTab.trim().toLowerCase()
        );

  console.log("Filtered Products:", filteredProducts);

  // Paginate products
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // If not admin, redirect or show unauthorized message
  if (!loading && role !== "admin") {
    return <div>You are not authorized to view this page.</div>;
  }

  const handleSearch = () => {
    console.log("Search Term:", searchTerm);
    console.log("Filter Type:", filterType);

    let filtered = productData;

    if (searchTerm.trim() !== "") {
      filtered = filtered.filter((product) => {
        const nameMatch =
          product.productName &&
          product.productName.toLowerCase().includes(searchTerm.toLowerCase());

        const skuMatch =
          product.sku &&
          product.sku.toLowerCase().includes(searchTerm.toLowerCase());

        const supplierMatch =
          product.supplierName &&
          product.supplierName.toLowerCase().includes(searchTerm.toLowerCase());

        return nameMatch || skuMatch || supplierMatch;
      });
    }

    // 🔥 Apply dropdown filtering even if search is empty
    if (filterType === "manual") {
      filtered = filtered.filter((product) => product.mainLocation);
    } else if (filterType === "price") {
      filtered = filtered.sort((a, b) => (a.price > b.price ? 1 : -1));
    } else if (filterType === "quantity") {
      filtered = filtered.sort((a, b) => (a.quantity > b.quantity ? 1 : -1));
    }

    console.log("Filtered Products:", filtered);
    setProductData(filtered);
  };

  const resetSearch = async () => {
    setSearchTerm(""); // Clear search input
    setFilterType("manual"); // Reset dropdown to default

    try {
      const querySnapshot = await getDocs(collection(db, "products"));
      const products = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setProductData(products); // Reset to all products
    } catch (error) {
      console.error("Error resetting search:", error);
    }
  };

  const handleExportToExcel = () => {
    if (!productData || productData.length === 0) {
      alert("No products to export!");
      return;
    }

    // Define Excel headers
    const excelData = productData.map((product) => ({
      ID: product.id,
      "Product Name": product.productName || "N/A",
      "Supplier Name": product.supplierName || "N/A",
      Location: product.mainLocation || "N/A",
      Price: product.price || "N/A",
      Quantity: product.quantity || "N/A",
      Category: product.category || "N/A",
    }));

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(excelData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

    // Write the file and trigger download
    XLSX.writeFile(workbook, "products_export.xlsx");
  };

  return (
    <div className='container-fluid py-3'>
      {/* Header */}
      <div className='mb-2'>
        <h4 className='text-success fw-bold'>{t("admin_products.products")}</h4>
        <p className='text-muted small'>{t("admin_products.all_products")}</p>
      </div>

      {/* Search and Filter Section */}
      <div className='d-flex align-items-center mb-2 small'>
        {/* Dropdown Filter */}
        <select
          className='form-select form-select-sm me-2'
          style={{ maxWidth: "150px" }}
          value={filterType}
          onChange={(e) => {
            setFilterType(e.target.value);
            handleSearch(); // 🔥 Call handleSearch when selecting a filter
          }}
        >
          <option value='manual'>{t("admin_products.location")}</option>
          <option value='price'>{t("admin_products.price")}</option>
          <option value='quantity'>{t("admin_products.quantity")}</option>
        </select>

        {/* Search Input */}
        <input
          type='text'
          className='form-control form-control-sm me-2'
          placeholder={t("admin_products.search_by")} // Remove extra {}
          style={{ maxWidth: "300px" }}
          value={searchTerm} // Bind state
          onChange={(e) => setSearchTerm(e.target.value)} // Update state
        />

        {/* Search Button */}
        <button className='btn btn-primary btn-sm' onClick={handleSearch}>
          {t("admin_products.search")}
        </button>

        <button className='btn btn-secondary btn-sm mx-2' onClick={resetSearch}>
          {t("admin_products.reset")}
        </button>
      </div>

      {/* Export & Additional Options */}
      <div className='d-flex align-items-center justify-content-between mb-2 small'>
        <button
          className='btn btn-outline-primary btn-sm'
          onClick={handleExportToExcel}
        >
          <Icon icon='download' className='me-2' />
          {t("admin_products.export_to")}
        </button>

        <div>
          <button className='btn btn-outline-secondary btn-sm me-2'>
            {t("admin_products.additional")}
          </button>
          <button
            className='btn btn-success btn-sm'
            onClick={() => navigate("/admin-add-products")}
          >
            {t("admin_products.add_new")}
          </button>
        </div>
      </div>

      {/* Dynamic Tabs */}
      <div className='mb-2 d-flex align-items-center'>
        {categories.map((category, index) => (
          <button
            key={index}
            onClick={() => {
              setSelectedTab(category);
              console.log("Selected Category:", category); // Debugging
            }}
            className={`btn btn-link text-decoration-none me-2 small ${
              selectedTab === category ? "text-success fw-bold" : "text-muted"
            }`}
          >
            {category}{" "}
            <span className='badge bg-light text-dark ms-1 small'>
              {category === "All"
                ? productData.length
                : productData.filter((product) => product.category === category)
                    .length}
            </span>
          </button>
        ))}
      </div>

      {/* Product Table */}
      <div className='table-responsive'>
        <table className='table table-striped table-hover table-sm'>
          <thead className='table-light small'>
            <tr>
              <th>
                <input type='checkbox' />
              </th>
              <th>{t("admin_products.product")}</th>
              <th>{t("admin_products.product_name")}</th>
              <th>{t("admin_products.supplier_name")}</th>
              <th>{t("admin_products.location")}</th>
              <th>{t("admin_products.quantity_pricing")}</th>
              <th>{t("admin_products.size")}</th>
              <th>{t("admin_products.color")}</th>
              <th>{t("admin_products.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {paginatedProducts.map((product) => (
              <tr key={product.id}>
                <td>
                  <input type='checkbox' />
                </td>
                <td>
                  <img
                    src={
                      product.mainImageUrl || "https://via.placeholder.com/50"
                    }
                    alt={product.productName || "Product Image"}
                    className='img-fluid rounded'
                    style={{ width: "40px", height: "40px" }}
                  />
                </td>
                <td className='small'>
                  {product.productName || "Unnamed Product"}
                </td>
                <td className='small'>{product.supplierName || "N/A"}</td>
                <td className='small'>{product.mainLocation || "N/A"}</td>
                <td className='small'>
                  {product.priceRanges && product.priceRanges.length > 0 ? (
                    <ul
                      style={{ listStyleType: "none", padding: 0, margin: 0 }}
                    >
                      {product.priceRanges.map((range, index) => (
                        <li key={index}>
                          Min: {range.minQty}, Max: {range.maxQty}, Price: SAR{" "}
                          {range.price}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td className='small'>
                  {product.sizes && product.sizes.length > 0 ? (
                    <ul
                      style={{ listStyleType: "none", padding: 0, margin: 0 }}
                    >
                      {product.sizes.map((size, index) => (
                        <li key={index}>{size}</li>
                      ))}
                    </ul>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td className='small'>
                  {product.colors && product.colors.length > 0 ? (
                    <ul
                      style={{ listStyleType: "none", padding: 0, margin: 0 }}
                    >
                      {product.colors.map((color, index) => (
                        <li key={index}>{color}</li>
                      ))}
                    </ul>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>
                  <div className='dropdown'>
                    <button
                      className='btn btn-outline-secondary btn-sm dropdown-toggle'
                      type='button'
                      id={`dropdownMenuButton-${product.id}`}
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      {t("admin_products.actions")}
                    </button>
                    <ul
                      className='dropdown-menu'
                      aria-labelledby={`dropdownMenuButton-${product.id}`}
                    >
                      <li>
                        <button
                          className='dropdown-item'
                          onClick={() =>
                            navigate(`/admin-edit-products/${product.id}`)
                          }
                        >
                          {t("admin_products.edit")}
                        </button>
                      </li>
                      <li>
                        <button
                          className='dropdown-item text-danger'
                          onClick={() => handleDelete(product.id)}
                        >
                          {t("admin_products.remove")}
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className='d-flex justify-content-between mt-3'>
        <button
          className='btn btn-sm btn-outline-secondary'
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          {t("admin_products.previous")}
        </button>
        <span className='small'>
          {t("admin_products.page")} {currentPage} {t("admin_products.of")}{" "}
          {totalPages}
        </span>
        <button
          className='btn btn-sm btn-outline-secondary'
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          {t("admin_products.next")}
        </button>
      </div>
    </div>
  );
};

export default Products;
