import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { useCart } from "../../context/CartContext";
import { useLocation } from "react-router-dom";
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
  collection,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import PaymentForm from "./PaymentForm";
import DeliveryAddress from "./DeliveryAddress";
import tabby from "../../assets/payment/tabby.png";
import tamara from "../../assets/payment/tamara.png";
import sadad from "../../assets/payment/sadad.png";
import { createInvoice } from "../../utils/gopayApi";
import LoadingSpinner from "../global/LoadingSpinner";
import masterCard from "../../assets/payment/master.png";
import visaCard from "../../assets/payment/visa.png";
import madaPay from "../../assets/payment/mada.png";
import applePay from "../../assets/payment/applepay.png";
import googlePay from "../../assets/payment/googlepay.jpeg";
import { ChevronDown } from "react-feather";
import { API_BASE_URL } from "../../utils/constants";

const CheckoutPage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { cartItems, supplierId } = location.state || {
    cartItems: [],
    supplierId: null,
  };
  const { clearCartInFirestore } = useCart();
  const [checkoutId, setCheckoutId] = useState(null);
  const [user, setUser] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedPayment, setSelectedPayment] = useState(null);

  useEffect(() => {
    if (!currentUser?.uid) return;

    const fetchUserFromFirestore = async () => {
      try {
        console.log("🛠 Fetching user from Firestore...");
        const userRef = doc(db, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          console.log("✅ Raw User Data from Firestore:", userData);

          setUser({
            buyerId: userData.buyerId || currentUser.uid,
            name: userData.name || "Unknown Buyer",
            email: userData.email || "billing@marsos.sa",
            street: userData.street || "123 Default St",
            city: userData.city || "Riyadh",
            state: userData.state || "Riyadh",
            country: userData.country || "SA",
            postcode: userData.postcode || "12345",
          });
        } else {
          console.error("❌ No user data found.");
        }
      } catch (error) {
        console.error("🔥 Error fetching user from Firestore:", error.message);
      }
    };

    fetchUserFromFirestore();
  }, [currentUser]); // ✅ Correct closing

  // 🔹 Calculate Total Amount (Only for Selected Supplier)
  const subtotal = cartItems.reduce(
    (acc, item) => acc + (item.price * item.quantity || 0),
    0
  );
  const shippingCost = cartItems.reduce(
    (acc, item) => acc + (item.shippingCost || 0),
    0
  ); // Dynamic Shipping Cost

  const vatRate = 0.15; // VAT is 15%
  const taxableAmount = subtotal + shippingCost; // VAT applies to both subtotal and shipping
  const tax = (taxableAmount * vatRate).toFixed(2);
  const totalAmount = (taxableAmount + parseFloat(tax)).toFixed(2);

  const [showTooltip, setShowTooltip] = useState(false);

  const handleAccordionClick = (event) => {
    const clickedButton = event.target.closest(".accordion-button");
    if (!clickedButton) return;

    const allButtons = document.querySelectorAll(".accordion-button");
    const allCollapseItems = document.querySelectorAll(".accordion-collapse");

    allButtons.forEach((button) => {
      button.style.backgroundColor = "transparent";
      button.style.color = "black";
      button.style.border = "none";
      button.style.boxShadow = "none";
    });

    allCollapseItems.forEach((collapse) => {
      if (
        collapse.id !==
        clickedButton.getAttribute("data-bs-target").substring(1)
      ) {
        collapse.classList.remove("show");
      }
    });

    setTimeout(() => {
      if (!clickedButton.classList.contains("collapsed")) {
        clickedButton.style.backgroundColor = "#2c6449";
        clickedButton.style.color = "white";
        clickedButton.style.border = "none";
        clickedButton.style.boxShadow = "none";
      }
    }, 150);
  };

  useEffect(() => {
    const accordionContainer = document.getElementById("paymentAccordion");

    if (accordionContainer) {
      accordionContainer.addEventListener("click", handleAccordionClick);
    }

    return () => {
      if (accordionContainer) {
        accordionContainer.removeEventListener("click", handleAccordionClick);
      }
    };
  }, []);

  // ✅ Fetch Checkout ID for HyperPay
  useEffect(() => {
    if (!user || cartItems.length === 0) return;

    console.log("📡 Preparing checkout with:", user);

    const fetchCheckoutId = async () => {
      try {
        const requestData = {
          amount: totalAmount,
          email: user.email,
          name: user.name,
          street: user.street,
          city: user.city,
          state: user.state,
          country: user.country,
          postcode: user.postcode,
          "customParameters[3DS2_enrolled]": "true", // Enroll in 3D Secure
          "customParameters[3DS2_challenge]": "true", // Ensure a challenge occurs
        };

        console.log("📡 Sending Checkout Request:", requestData);

        const response = await axios.post(
          `${API_BASE_URL}/create-checkout`,
          requestData
        );

        console.log("✅ Checkout Request Sent. Response:", response.data);
        setCheckoutId(response.data.checkoutId);
      } catch (error) {
        console.error(
          "❌ Error fetching checkoutId:",
          error.response?.data || error.message
        );
      }
    };

    fetchCheckoutId();
  }, [user, cartItems, totalAmount]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const resourcePath = params.get("resourcePath");

    if (!resourcePath) return;

    const verifyAndSaveOrder = async () => {
      setLoading(true);
      try {
        const response = await axios.post(`${API_BASE_URL}/verify-payment`, {
          resourcePath,
        });

        const data = response.data;
        const successCodes = ["000.000.000", "000.100.110", "000.100.112"];

        if (!successCodes.includes(data.result?.code)) {
          throw new Error("Payment failed verification.");
        }

        // ✅ Fetch cart from Firestore directly (in case cartItems is empty)
        const cartRef = doc(db, "carts", currentUser?.uid);
        const cartSnap = await getDoc(cartRef);
        const firestoreCart = cartSnap.exists()
          ? cartSnap.data().items || []
          : [];

        // ✅ Save order with real cart items
        await addDoc(collection(db, "orders"), {
          userId: currentUser?.uid || "guest",
          userEmail: data.customerEmail || user?.email,
          userName: user?.name || "Guest Buyer",
          totalAmount: data.amount,
          cardBrand: data.cardBrand || "N/A",
          transactionId: data.transactionId || "N/A",
          merchantTransactionId: data.merchantTransactionId || "N/A",
          paymentMethod: data.paymentType || "Card",
          createdAt: new Date(),
          orderStatus: "Paid",
          items: firestoreCart,
        });

        console.log("✅ Order created and saved with cart items");

        // ✅ Clear cart
        await clearCartInFirestore();

        // ✅ Redirect
        navigate("/orders");
      } catch (error) {
        console.error("🔥 Error verifying payment:", error);
        alert("Payment verification failed. Please contact support.");
      } finally {
        setLoading(false);
      }
    };

    verifyAndSaveOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ✅ If no supplier is selected, prevent rendering
  if (!supplierId) {
    return (
      <div className='container my-4'>
        <h4 className='text-center text-danger'>
          No supplier selected for checkout.
        </h4>
      </div>
    );
  }

  const handleCheckout = async () => {
    if (!selectedPayment) {
      alert("Please select a payment method.");
      return;
    }

    if (selectedPayment === "sadad") {
      await handleSadadPayment();
    } else if (selectedPayment === "cod") {
      await handleCodPayment();
    } else {
      alert("❌ This checkout process is only for SADAD and COD.");
    }
  };

  const handleWalletPayment = () => {
    if (selectedPayment === "applepay") {
      alert("🔒 Apple Pay is currently under development.");
      // Future: integrate Apple Pay here
    } else if (selectedPayment === "googlepay") {
      alert("🔒 Google Pay is currently under development.");
      // Future: integrate Google Pay here
    }
  };

  const handleCodPayment = async () => {
    if (!user || cartItems.length === 0) {
      console.error("❌ User or cart items missing.");
      return;
    }

    try {
      const orderData = {
        userId: user.buyerId,
        userEmail: user.email,
        userName: user.name,
        totalAmount: totalAmount,
        items: cartItems,
        orderStatus: "Pending", // COD orders start as pending
        createdAt: new Date(),
        paymentMethod: "COD",
      };

      // ✅ Store COD order in Firestore
      const ordersRef = collection(db, "orders");
      await addDoc(ordersRef, orderData);

      console.log("✅ COD Order saved successfully!");

      alert(
        "Your order has been placed successfully! You will pay on delivery."
      );
      window.location.href = "/orders"; // Redirect to orders page
    } catch (error) {
      console.error("🔥 Error processing COD order:", error);
      alert("❌ Something went wrong. Please try again.");
    }
  };

  const storeOrderInFirestore = async (
    billNumber,
    sadadNumber,
    totalAmount,
    items,
    paymentUrl
  ) => {
    if (!currentUser?.uid) return;
    if (!user) return;

    const formattedTotalAmount = Number(totalAmount) || 0;

    const payload = {
      userId: currentUser.uid,
      userEmail: user?.email ?? currentUser?.email ?? "buyer@example.com",
      userName: user?.name ?? "Guest Buyer",
      totalAmount: formattedTotalAmount.toFixed(2),
      items: (items ?? []).map((item) => ({
        ...item,
        name: item.name ?? "Unnamed Product",
        quantity: item.quantity ?? 1,
        price: item.price ?? 0,
      })),
      orderStatus: "Pending",
      createdAt: new Date(),
      paymentMethod: "Sadad",
      billNumber: billNumber ?? "Unknown",
      sadadNumber: sadadNumber ?? "N/A",
      paymentUrl: paymentUrl ?? "",
    };

    console.log("🚨 Final payload with types:");
    for (const key in payload) {
      console.log(`${key}:`, payload[key], "| type:", typeof payload[key]);
    }

    try {
      await addDoc(collection(db, "orders"), payload);
      console.log("✅ Order successfully saved.");
    } catch (error) {
      console.error("🔥 Firestore Error:", error);
      alert("Error saving order, please try again.");
    }
  };

  const clearSupplierItemsFromCart = async (supplierId) => {
    if (!currentUser?.uid) return;

    const cartRef = doc(db, "carts", currentUser.uid);

    try {
      const cartSnapshot = await getDoc(cartRef);
      if (cartSnapshot.exists()) {
        const cartData = cartSnapshot.data();
        const filteredItems = (cartData.items || []).filter(
          (item) => item.supplierId !== supplierId
        );

        // ✅ Update cart in Firestore after removing supplier's items
        await updateDoc(cartRef, { items: filteredItems });
        console.log("✅ Cart updated after SADAD order.");
      } else {
        // ✅ If no cart exists, create an empty cart document
        await setDoc(cartRef, { items: [] });
      }
    } catch (error) {
      console.error("🔥 Error updating cart:", error);
      alert("Error updating cart. Please try again.");
    }
  };

  // SADAD RELATED GOPAY
  const handleSadadPayment = async () => {
    setProcessing(true);
    setLoading(true);

    try {
      const issueDate = new Date().toISOString().split("T")[0];
      const expireDate = new Date(new Date().setDate(new Date().getDate() + 7))
        .toISOString()
        .split("T")[0];

      // 🔹 Ensure Buyer Name is Correctly Retrieved
      const buyerName =
        user?.name && user?.name.trim() !== "" ? user.name : "Guest Buyer";

      // 🔹 Calculate Shipping Cost
      const shippingCost = cartItems.reduce(
        (acc, item) => acc + (item.shippingCost || 0),
        0
      );

      const invoiceData = {
        customerIdType: "NAT",
        customerFullName: buyerName, // ✅ Ensure accurate buyer name is sent
        customerEmailAddress: user?.email || "buyer@example.com",
        customerMobileNumber: user?.phoneNumber || "966500000000",
        issueDate,
        expireDate,
        serviceName: "E-commerce Order",
        billItemList: [
          ...cartItems.map((item) => ({
            name: item.name,
            quantity: item.quantity,
            unitPrice: item.price,
            discount: item.discount || 0,
            discountType: "FIXED",
            vat: 0.15,
          })),
          ...(shippingCost > 0
            ? [
                {
                  name: "Shipping Fee",
                  quantity: 1,
                  unitPrice: shippingCost,
                  discount: 0,
                  discountType: "FIXED",
                  vat: 0,
                },
              ]
            : []),
        ],
      };

      console.log("📡 Sending Invoice Data to GoPay:", invoiceData);

      // ✅ Call `createInvoice` from `gopayApi.js`
      const invoiceResponse = await createInvoice(invoiceData);

      console.log("✅ Raw API Response from Backend:", invoiceResponse);

      // ✅ Extract `billNumber`, `sadadNumber`, and `paymentUrl`
      if (
        !invoiceResponse ||
        !invoiceResponse.data ||
        !invoiceResponse.data.billNumber ||
        !invoiceResponse.data.sadadNumber
      ) {
        console.error(
          "❌ Missing Bill Number or Sadad Number in API response."
        );
        console.error("🚨 Full API Response:", invoiceResponse);
        throw new Error("Failed to retrieve Sadad Number.");
      }

      const billNumber = invoiceResponse.data.billNumber;
      const sadadNumber = invoiceResponse.data.sadadNumber;
      const paymentUrl = invoiceResponse.paymentUrl;

      console.log("✅ Bill Number:", billNumber);
      console.log("✅ Sadad Number:", sadadNumber);
      console.log("✅ Payment Link:", paymentUrl);

      // ✅ Store order in Firestore with billNumber and sadadNumber
      await storeOrderInFirestore(
        billNumber,
        sadadNumber,
        totalAmount,
        cartItems,
        paymentUrl
      );

      // ✅ Remove ordered items from the cart
      await clearSupplierItemsFromCart(supplierId);

      console.log(`✅ Order for supplier ${supplierId} saved successfully!`);

      // ✅ Redirect user to Orders Page
      navigate(`/sadad-confirmation/${billNumber}`);
    } catch (error) {
      console.error("🔥 Error processing SADAD payment:", error);
      alert(`❌ SADAD Payment failed: ${error.message}`);
    } finally {
      setProcessing(false);
      setLoading(false);
    }
  };

  return (
    <div className='container my-4'>
      {processing && (
        <div className='alert alert-info text-center' role='alert'>
          <strong>Generating Invoice...</strong> Please wait.
        </div>
      )}

      {loading && (
        <div className='loading-overlay'>
          <LoadingSpinner />
          <h4 style={{ color: "white", marginTop: "10px" }}>
            Processing Payment... Please wait.
          </h4>
        </div>
      )}

      <div
        className='mb-3 text-center text-white'
        style={{
          position: "relative",
          display: "block",
          width: "100%",
          backgroundColor: "#2c6449",
          padding: "8px 16px",
          borderRadius: "4px",
        }}
      >
        <h4 className='d-inline-flex align-items-center gap-1 m-0 justify-content-center'>
          Secure checkout
          <ChevronDown
            onClick={() => setShowTooltip((prev) => !prev)}
            style={{
              cursor: "pointer",
              transform: showTooltip ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
            size={16}
          />
        </h4>

        {showTooltip && (
          <div
            className='position-absolute bg-white text-dark p-3 shadow rounded'
            style={{
              top: "100%",
              left: "50%",
              transform: "translateX(-50%)",
              width: "90%", // 🔄 use percentage for responsiveness
              maxWidth: "520px",
              zIndex: 1000,
              fontSize: "14px",
              marginTop: "8px",
              textAlign: "left",
            }}
          >
            <p className='m-0'>
              We secure your payment and personal information when you share or
              save it with us. We don't share payment details with third-party
              sellers. We don't sell your information to others.{" "}
              <button
                type='button'
                className='btn btn-link p-0 m-0 align-baseline'
                style={{
                  fontSize: "13px",
                  color: "#2c6449",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Learn more
              </button>
            </p>
          </div>
        )}
      </div>

      <div className='row gx-2'>
        {/* 📌 COLUMN 1: Delivery Address & Review Order (LEFT COLUMN) */}
        <div className='col-md-4 d-flex flex-column gap-3'>
          {/* 🚚 Delivery Address */}
          <div className='border p-3 rounded bg-white shadow-sm'>
            <h5 className='mb-3 small fw-bold' style={{ color: "#2c6449" }}>
              1. Delivery Address
            </h5>

            <DeliveryAddress />
          </div>
        </div>

        <div className='col-md-4 d-flex flex-column gap-3'>
          {/* 🛍 Review Order */}
          <div className='border p-3 rounded bg-white shadow-sm'>
            <h5 className='mb-3 small fw-bold' style={{ color: "#2c6449" }}>
              2. Review Your Order
            </h5>

            {cartItems.length > 0 ? (
              <ul className='list-group'>
                {cartItems.map((item, index) => (
                  <li
                    key={index}
                    className='list-group-item d-flex align-items-center'
                  >
                    {/* Product Image */}
                    <img
                      src={item.mainImageUrl}
                      alt={item.color}
                      className='img-thumbnail'
                      style={{
                        width: "70px",
                        height: "50px",
                        objectFit: "cover",
                      }}
                    />

                    {/* Product Details */}
                    <div className='flex-grow-1 ms-2 small'>
                      <p className='mb-1 fw-bold'>
                        {item.name || "Product Name"}
                      </p>
                      <p className='mb-1 text-muted'>
                        Color: {item.color || "N/A"}
                      </p>
                      <p className='mb-1 text-muted'>
                        Size: {item.size || "N/A"}
                      </p>
                      <p className='mb-1 text-muted'>
                        Shipping: SR {item.shippingCost || "0.00"}
                      </p>
                      <p className='mb-0'>Qty: {item.quantity}</p>
                    </div>

                    {/* Price (Aligned Right) */}
                    <p className='fw-bold ms-auto small'>SR {item.price}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <p className='small'>❌ No items found for this supplier.</p>
            )}
          </div>
        </div>

        {/* 📌 COLUMN 2: Order Summary & Payment Method (RIGHT COLUMN) */}
        <div className='col-md-4 d-flex flex-column gap-3'>
          <div className='border p-3 rounded bg-white shadow-sm'>
            {/* 🔹 Order Summary */}
            <h5 className='mb-3 small fw-bold' style={{ color: "#2c6449" }}>
              3. Order Summary
            </h5>

            <div className='border p-2 rounded small bg-light'>
              <p className='d-flex justify-content-between mb-1'>
                <span>Subtotal:</span>
                <span>SR {subtotal.toFixed(2)}</span>
              </p>
              <p className='d-flex justify-content-between mb-1'>
                <span>Shipping:</span>
                <span>SR {shippingCost.toFixed(2)}</span>
              </p>
              <p className='d-flex justify-content-between mb-1'>
                <span>VAT Cost (15%):</span>
                <span>SR {tax}</span>
              </p>
              <hr />
              <h6 className='d-flex justify-content-between fw-bold'>
                <span>Total:</span>
                <span>SR {totalAmount}</span>
              </h6>
            </div>

            {/* 🔹 Payment Method Section */}
            <h5
              className='mt-4 mb-3 small fw-bold'
              style={{ color: "#2c6449" }}
            >
              4. Select Payment Method
            </h5>
            <div className='accordion small' id='paymentAccordion'>
              {/* Credit/Debit Card */}
              <div className='accordion-item'>
                <h2 className='accordion-header'>
                  <button
                    className='accordion-button collapsed small'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseCard'
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      border: "none",
                      boxShadow: "none",
                      padding: "8px 12px",
                      fontSize: "14px",
                      height: "36px",
                      transition: "background-color 0.3s ease, color 0.3s ease",
                    }}
                  >
                    Credit/Debit Card
                    <img
                      src={madaPay}
                      alt='Mada Pay'
                      style={{ maxHeight: "34px", marginLeft: "8px" }}
                    />
                    <img
                      src={masterCard}
                      alt='Master Card'
                      style={{ maxHeight: "26px", marginLeft: "2px" }}
                    />
                    <img
                      src={visaCard}
                      alt='Visa Card'
                      style={{ maxHeight: "34px", marginLeft: "2px" }}
                    />
                  </button>
                </h2>
                <div id='collapseCard' className='accordion-collapse collapse'>
                  <div className='accordion-body' style={{ padding: "8px" }}>
                    <PaymentForm checkoutId={checkoutId} />
                  </div>
                </div>
              </div>

              {/* Digital Wallets Section: Apple Pay + Google Pay */}
              <div className='accordion-item'>
                <h2 className='accordion-header'>
                  <button
                    className='accordion-button collapsed small'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseWallets'
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      border: "none",
                      boxShadow: "none",
                      padding: "8px 12px",
                      fontSize: "14px",
                      height: "36px",
                      transition: "background-color 0.3s ease, color 0.3s ease",
                    }}
                  >
                    Digital Wallets
                    <img
                      src={applePay}
                      alt='Apple Pay'
                      style={{ maxHeight: "34px", marginLeft: "8px" }}
                    />
                    <img
                      src={googlePay}
                      alt='Google Pay'
                      style={{ maxHeight: "20px", marginLeft: "8px" }}
                    />
                  </button>
                </h2>
                <div
                  id='collapseWallets'
                  className='accordion-collapse collapse'
                >
                  <div className='accordion-body' style={{ padding: "8px" }}>
                    <div className='d-flex gap-2'>
                      <button
                        className={`btn btn-outline-secondary btn-sm w-50 d-flex align-items-center justify-content-center gap-2 ${
                          selectedPayment === "applepay" ? "active" : ""
                        }`}
                        onClick={() => setSelectedPayment("applepay")}
                      >
                        Apple Pay
                        <img
                          src={applePay}
                          alt='Apple Pay'
                          style={{ maxHeight: "34px", marginLeft: "8px" }}
                        />
                      </button>

                      <button
                        className={`btn btn-outline-secondary btn-sm w-50 d-flex align-items-center justify-content-center gap-2 ${
                          selectedPayment === "googlepay" ? "active" : ""
                        }`}
                        onClick={() => setSelectedPayment("googlepay")}
                      >
                        Google Pay
                        <img
                          src={googlePay}
                          alt='Google Pay'
                          style={{ maxHeight: "20px", marginLeft: "8px" }}
                        />
                      </button>
                    </div>

                    {(selectedPayment === "applepay" ||
                      selectedPayment === "googlepay") && (
                      <div className='text-center mt-3'>
                        <button
                          className='btn btn-primary w-100'
                          onClick={handleWalletPayment}
                        >
                          Pay Now
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Buy Now, Pay Later Section */}
              <div className='accordion-item'>
                <h2 className='accordion-header'>
                  <button
                    className='accordion-button collapsed small'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseBNPL'
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      border: "none",
                      boxShadow: "none",
                      padding: "8px 12px",
                      fontSize: "14px",
                      height: "36px",
                      transition: "background-color 0.3s ease, color 0.3s ease",
                    }}
                  >
                    Buy Now, Pay Later
                    <img
                      src={tamara}
                      alt='Tamara'
                      style={{ maxHeight: "40px", marginLeft: "8px" }}
                    />
                    <img
                      src={tabby}
                      alt='Tabby'
                      style={{ maxHeight: "34px", marginLeft: "8px" }}
                    />
                  </button>
                </h2>

                <div id='collapseBNPL' className='accordion-collapse collapse'>
                  <div className='accordion-body' style={{ padding: "8px" }}>
                    <div className='d-flex gap-2'>
                      <button
                        className={`btn btn-outline-secondary btn-sm w-50 d-flex align-items-center justify-content-center gap-2 ${
                          selectedPayment === "tamara" ? "active" : ""
                        }`}
                        onClick={() => setSelectedPayment("tamara")}
                      >
                        Tamara
                        <img
                          src={tamara}
                          alt='Tamara'
                          style={{ maxHeight: "40px", marginLeft: "8px" }}
                        />
                      </button>

                      <button
                        className={`btn btn-outline-secondary btn-sm w-50 d-flex align-items-center justify-content-center gap-2 ${
                          selectedPayment === "tabby" ? "active" : ""
                        }`}
                        onClick={() => setSelectedPayment("tabby")}
                      >
                        Tabby
                        <img
                          src={tabby}
                          alt='Tabby'
                          style={{ maxHeight: "34px", marginLeft: "8px" }}
                        />
                      </button>
                    </div>

                    {/* Show Pay Now Button only if a BNPL option is selected */}
                    {(selectedPayment === "tamara" ||
                      selectedPayment === "tabby") && (
                      <div className='text-center mt-3'>
                        <button className='btn btn-primary w-100'>
                          Pay Now
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Other Payment Options Section */}
              <div className='accordion-item'>
                <h2 className='accordion-header'>
                  <button
                    className='accordion-button collapsed small'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseOther'
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      border: "none",
                      boxShadow: "none",
                      padding: "8px 12px",
                      fontSize: "14px",
                      height: "36px",
                      transition: "background-color 0.3s ease, color 0.3s ease",
                    }}
                  >
                    Other Payment Options
                    <img
                      src={sadad}
                      alt='Sadad'
                      style={{ maxHeight: "34px", marginLeft: "8px" }}
                    />
                  </button>
                </h2>

                <div id='collapseOther' className='accordion-collapse collapse'>
                  <div className='accordion-body' style={{ padding: "8px" }}>
                    <div className='d-flex gap-2'>
                      <button
                        className={`btn btn-outline-secondary btn-sm w-50 d-flex align-items-center justify-content-center gap-2 ${
                          selectedPayment === "sadad" ? "active" : ""
                        }`}
                        onClick={() => setSelectedPayment("sadad")}
                      >
                        SADAD
                        <img
                          src={sadad}
                          alt='Sadad'
                          style={{ maxHeight: "34px", marginLeft: "8px" }}
                        />
                      </button>
                    </div>

                    <button
                      className='btn btn-primary w-100 mt-3'
                      onClick={handleCheckout}
                      disabled={
                        !selectedPayment ||
                        (selectedPayment !== "sadad" &&
                          selectedPayment !== "cod")
                      }
                    >
                      Pay Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
