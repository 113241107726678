// File: ProductChat.js
import React, { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  setDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { useAuth } from "../../context/AuthContext";
import LoadingSpinner from "../global/LoadingSpinner";
import { useParams, useLocation } from "react-router-dom";
import MiniProductDetails from "./MiniProductDetails";
import ChatMessages from "../chat/shared/ChatMessages";

const ProductChat = () => {
  const { currentUser } = useAuth();
  const { chatId } = useParams();
  const location = useLocation();

  const [messages, setMessages] = useState([]);
  const [chatDetails, setChatDetails] = useState(null);
  const [supplierName, setSupplierName] = useState("Supplier");
  const [buyerName, setBuyerName] = useState("Buyer");
  const [product, setProduct] = useState(null);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(true);

  const initialProductId = location.state?.productId;
  const initialSupplierId = location.state?.supplierId;

  const productId = initialProductId || chatDetails?.productId;
  const supplierId = initialSupplierId || chatDetails?.supplierId;

  useEffect(() => {
    if (!chatId || !currentUser) return;

    const chatRef = doc(db, "productChats", chatId);

    const initChat = async () => {
      const chatSnap = await getDoc(chatRef);

      if (!chatSnap.exists()) {
        if (!initialProductId || !initialSupplierId) {
          setNotification("❌ Missing product/supplier info.");
          setLoading(false);
          return;
        }

        await setDoc(chatRef, {
          buyerId: currentUser.uid,
          supplierId: initialSupplierId,
          productId: initialProductId,
          messages: [],
          createdAt: new Date().toISOString(),
        });
      }

      const unsubscribe = onSnapshot(chatRef, (docSnap) => {
        if (docSnap.exists()) {
          const chatData = docSnap.data();
          setChatDetails(chatData);
          setMessages(chatData.messages || []);
        }
      });

      const snapData = (await getDoc(chatRef)).data();
      fetchUserNames(snapData.buyerId, snapData.supplierId);
      fetchProduct(snapData.productId);
      setLoading(false);

      return () => unsubscribe();
    };

    const cleanup = initChat();
    return () => {
      cleanup.then((cb) => cb?.());
    };
  }, [chatId, currentUser, initialProductId, initialSupplierId]);

  const fetchUserNames = async (buyerId, supplierId) => {
    const buyerSnap = await getDoc(doc(db, "users", buyerId));
    const supplierSnap = await getDoc(doc(db, "users", supplierId));

    if (buyerSnap.exists()) setBuyerName(buyerSnap.data().name || "Buyer");
    if (supplierSnap.exists())
      setSupplierName(supplierSnap.data().name || "Supplier");
  };

  const fetchProduct = async (id) => {
    const productRef = doc(db, "products", id);
    const docSnap = await getDoc(productRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      setProduct({
        ...data,
        name: data.productName,
        mainImage: data.mainImageUrl,
      });
    }
  };

  const handleSendMessage = async (messageText, senderName) => {
    if (!messageText.trim()) return;

    const chatRef = doc(db, "productChats", chatId);
    const message = {
      senderId: currentUser.uid,
      senderName,
      message: messageText.trim(),
      timestamp: new Date().toISOString(),
    };

    await updateDoc(chatRef, {
      messages: arrayUnion(message),
    });
  };

  if (loading) return <LoadingSpinner />;

  if (!productId || !supplierId) {
    return (
      <div className='container py-3'>
        <div className='alert alert-danger text-center small'>
          ⚠️ Missing product or supplier information.
        </div>
      </div>
    );
  }

  return (
    <div className='container py-3'>
      <h6 className='fw-bold mb-3 text-center text-md-start'>
        Chat with {currentUser.uid === supplierId ? buyerName : supplierName}
      </h6>

      <div className='row g-3'>
        {/* LEFT COLUMN: Product Details */}
        <div className='col-12 col-md-5'>
          <MiniProductDetails productId={productId} supplierId={supplierId} />
        </div>

        {/* RIGHT COLUMN: Chat Box */}
        <div className='col-12 col-md-7'>
          {notification && (
            <div className='alert alert-danger small py-2 px-3 mb-2'>
              {notification}
            </div>
          )}

          <div className='mb-2 small text-muted'>
            Product: <strong>{product?.name}</strong>
          </div>

          <ChatMessages messages={messages} onSendMessage={handleSendMessage} />
        </div>
      </div>
    </div>
  );
};

export default ProductChat;
