import React, { useState, useEffect } from "react";
import { BsFillShieldLockFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "otp-input-react";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  onAuthStateChanged,
} from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../firebase/config";
import { collection, query, where, getDocs } from "firebase/firestore";
import Notification from "../global/Notification";

const UserLogin = () => {
  const [otp, setOtp] = useState("");
  const [countryCode, setCountryCode] = useState("+966"); // Default country code
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const navigate = useNavigate();

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    title: "",
    message: "",
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const showNotification = (title, message) => {
    setNotificationContent({ title, message });
    setIsNotificationOpen(true);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;

    if (/^\d{0,10}$/.test(value)) {
      if (value.startsWith("0")) {
        showNotification("Phone number cannot start with 0");
      } else {
        setPhone(value);
        setIsButtonDisabled(value.length < 9);
      }
    } else {
      showNotification(
        "Please Enter Only Numbers and Maximum of 10 Digits Only"
      );
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          localStorage.setItem(
            "user",
            JSON.stringify({ ...userData, uid: user.uid })
          );

          const role = userData.role || "buyer";
          const redirectRoute = role === "buyer" ? "/" : "/supplier-dashboard";
          navigate(redirectRoute);
        } else {
          navigate("/register");
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {
            console.log("Recaptcha expired, please verify again.");
          },
        },
        auth
      );
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;
    const fullPhoneNumber = `${countryCode}${phone}`.trim();

    signInWithPhoneNumber(auth, fullPhoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sent successfully!");
      })
      .catch((error) => {
        console.error("Error during sign-in:", error);
        setLoading(false);
      });
  }

  async function onOTPVerify() {
    try {
      setLoading(true);
      const result = await window.confirmationResult.confirm(otp);
      const fullPhoneNumber = `${countryCode}${phone}`.trim();
      const user = result.user;

      localStorage.setItem("userPhone", fullPhoneNumber);

      const usersRef = collection(db, "users");
      let q = query(usersRef, where("uid", "==", user.uid));
      let querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        q = query(usersRef, where("phone", "==", fullPhoneNumber));
        querySnapshot = await getDocs(q);
      }

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        const role = userData.role || "buyer";
        const redirectRoute = role === "buyer" ? "/" : "/supplier-dashboard";

        localStorage.setItem("userId", user.uid);
        localStorage.setItem("userName", userData.name || "Anonymous");
        localStorage.setItem("userRole", role);

        toast.success("Login successful!");
        setLoading(false);
        navigate(redirectRoute);
      } else {
        setLoading(false);
        navigate("/register");
      }
    } catch (err) {
      console.error("OTP verification or Firestore query failed:", err);
      setLoading(false);
      toast.error("Invalid OTP or Firestore query issue. Please try again.");
    }
  }
  return (
    <div
      className='d-flex flex-column flex-lg-row'
      style={{
        minHeight: "calc(100vh - 140px)", // Adjust for navbar height
        width: "100%", // Use 100% instead of 100vw to avoid scrollbar issues
        overflow: "hidden", // Prevent unwanted overflow
        margin: 0,
        padding: 0,
      }}
    >
      {/* Left Column */}
      <div
        className='d-flex flex-column align-items-center justify-content-center w-100'
        style={{
          flex: 1,
          backgroundColor: "#f7f7f7",
          padding: "1rem",
          boxSizing: "border-box", // Ensures padding doesn't cause overflow
          maxWidth: "100%", // Prevent child elements from exceeding container width
        }}
      >
        <Toaster toastOptions={{ duration: 4000 }} />
        <div id='recaptcha-container'></div>

        <div className='text-center mb-4'>
          <img
            src='./logo-marsos.svg'
            alt='Logo'
            style={{
              width: "80px",
              marginBottom: "10px",
              maxWidth: "100%", // Prevent logo overflow
            }}
          />
          <h2
            style={{
              color: "#2d6a4f",
              fontWeight: "bold",
              wordWrap: "break-word", // Ensure long text breaks within the container
            }}
          >
            Login or Register
          </h2>
        </div>

        <form
          onSubmit={(e) => e.preventDefault()}
          className='p-4 border rounded shadow-sm bg-white w-100'
          style={{
            maxWidth: "400px",
            boxSizing: "border-box",
          }}
        >
          {showOTP ? (
            <>
              {/* Title */}
              <h5
                className='text-center mb-4'
                style={{
                  color: "#2d6a4f",
                  fontWeight: "bold",
                }}
              >
                Enter OTP
              </h5>

              {/* Lock Icon */}
              <div className='d-flex justify-content-center mb-4'>
                <BsFillShieldLockFill size={40} color='#2d6a4f' />
              </div>

              {/* OTP Input */}
              <div
                className='d-flex justify-content-center w-100'
                style={{
                  maxWidth: "400px", // Prevent the container from being too wide
                  margin: "0 auto", // Center horizontally
                }}
              >
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6} // Number of OTP digits
                  otpType='number' // OTP type (e.g., numeric input)
                  disabled={false}
                  autoFocus
                  className='opt-container mb-3'
                  inputStyle={{
                    width: "3rem", // Width of individual OTP boxes
                    height: "3rem", // Height of individual OTP boxes
                    margin: "0.5rem", // Spacing between boxes
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    textAlign: "center",
                    fontSize: "1.2rem",
                  }}
                  containerStyle={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              </div>

              {/* Verify Button */}
              <div
                className='d-flex justify-content-center'
                style={{ width: "100%" }}
              >
                <button
                  onClick={onOTPVerify}
                  className='btn btn-success'
                  style={{
                    width: "70%", // Reduce button width
                    maxWidth: "300px", // Prevent it from being too large
                    textAlign: "center",
                  }}
                  disabled={isButtonDisabled} // Disable button if needed
                >
                  {loading && (
                    <CgSpinner size={20} className='mt-1 animate-spin' />
                  )}
                  Verify OTP
                </button>
              </div>
            </>
          ) : (
            <>
              <h5 className='text-center mb-4' style={{ color: "#2d6a4f" }}>
                Verify your account
              </h5>
              <div className='input-group mb-3'>
                <select
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  className='form-select'
                  style={{
                    maxWidth: "90px",
                    minWidth: "60px",
                  }}
                >
                  <option value='+966'>+966 (Saudi Arabia)</option>
                  <option value='+971'>+971 (United Arab Emirates)</option>
                  <option value='+973'>+973 (Bahrain)</option>
                  <option value='+965'>+965 (Kuwait)</option>
                  <option value='+968'>+968 (Oman)</option>
                  <option value='+974'>+974 (Qatar)</option>
                  <option value='+63'>+63</option>
                </select>

                <input
                  type='tel'
                  placeholder='Enter your phone number'
                  value={phone}
                  onChange={handlePhoneChange}
                  className='form-control custom-placeholder'
                  required
                  style={{
                    maxWidth: "100%",
                  }}
                />
              </div>
              <div className='form-check mb-3'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  id='agreementCheckboxSignup'
                  required
                />
                <label
                  className='form-check-label'
                  htmlFor='agreementCheckboxSignup'
                  style={{ fontSize: "0.9rem", color: "#2d6a4f" }}
                >
                  I agree to the{" "}
                  <a
                    href='/updated-terms-and-conditions'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ color: "#2c6449" }}
                  >
                    Terms & Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    href='/updated-privacy-policy'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ color: "#2c6449" }}
                  >
                    Privacy Policy
                  </a>
                  .
                </label>
              </div>
              <div
                className='d-flex justify-content-center'
                style={{ width: "100%" }}
              >
                <button
                  onClick={onSignup}
                  className='btn btn-success'
                  style={{
                    width: "70%", // Reduce the width of the button
                    maxWidth: "300px", // Optional: Prevent the button from being too large
                    textAlign: "center",
                  }}
                  disabled={isButtonDisabled} // Disable button when needed
                >
                  {loading && (
                    <CgSpinner size={20} className='mt-1 animate-spin' />
                  )}
                  Send code via SMS
                </button>
              </div>
            </>
          )}
        </form>

        <Notification
          isOpen={isNotificationOpen}
          onClose={() => setIsNotificationOpen(false)}
          title={notificationContent.title}
          message={notificationContent.message}
          duration={5000}
        />
      </div>

      {/* Right Column */}
      <div
        style={{
          flex: 1,
          background: "linear-gradient(to right, #f7f7f7, #2c6449)",
          display: "none",
          maxWidth: "100%", // Prevent the gradient from causing overflow
        }}
        className='d-none d-lg-block'
      ></div>
    </div>
  );
};

export default UserLogin;
